import {Route, Routes} from "react-router-dom";
import AuthPage from "../pages/Auth";
import Products from "../pages/products";
import {useState} from "react";
import {PrivateRoute} from "./private-route";
import {authToken} from "../ui-utils/general-variable";
import Orders from "../pages/orders";
import OrderDetail from "../pages/order-detail";
import Settings from "../pages/settings";
import ProductDetail from "../pages/product-detail";
import ForgotPassword from "../pages/forgot-password";
import Balance from "../pages/balance";

export default function RoutePage() {
    const isAuthenticated = authToken
    const [routeList] = useState([
        {
            path: '/products/product-list',
            component: <Products/>
        }, {
            path: '/products/product-list/product-detail/:id',
            component: <ProductDetail/>
        }, {
            path: '/orders/order-list',
            component: <Orders/>
        }, {
            path: '/orders/order-list/order-detail/:id',
            component: <OrderDetail/>
        }, {
            path: '/settings',
            component: <Settings/>
        }, {
            path: '/balance',
            component: <Balance/>
        }
    ])

    return (
        <Routes>
            <Route path="/" element={isAuthenticated ? <Orders/> : <AuthPage/>}/>
            {
                routeList.map((item, index) => (
                    <Route key={index.toString()} path={item.path} element={<PrivateRoute children={item.component}/>}/>
                ))
            }
            <Route key={'forgot-password'} path={'forgot-password'} element={<ForgotPassword />}/>
        </Routes>
    )
}
