import CourierIcon from "../../images/raw-assets/Group 1954.svg";
import CopyIcon from "../../images/raw-assets/Group 1950.svg";
import PrintIcon from "../../images/raw-assets/Group 1951.svg";
import {getCourierImage} from "../../ui-utils/general-variable";
import {useDispatch, useSelector} from "react-redux";
import {message} from "antd";
import {setOrderListToPrint} from "../../reducers/order.reducer";
import {setToolsPrintLabelModalVisibility} from "../../reducers/tools.reducer";

export default function OrderCourierDetailComponent() {
    const dispatch = useDispatch()
    const {orderDetail} = useSelector(state => state.orderReducer)

    const copyToClipboard = () => {
        navigator.clipboard.writeText(orderDetail?.awb)
        message.success('Copied!!');
    }

    const openPrintLabelModal = () => {
        dispatch(setOrderListToPrint([orderDetail]))
        dispatch(setToolsPrintLabelModalVisibility('visible'))
    }

    return (
        <div>
            <div className={'row items-center space-x-2'}>
                <img className="w-8" src={CourierIcon} alt={'btn'}/>
                <p className={"font-bold text-dark-grey-blue"}>Jasa Pengiriman</p>
            </div>
            <div className={'row items-center justify-between pl-10 items-center'}>
                <div className={"row space-x-5 items-center"}>
                    {!!orderDetail?.shipping_method &&
                        <img src={getCourierImage(orderDetail?.shipping_method)} className={"w-12"}/>}
                    <div className={"text-dark-blue"}>
                        <p className={"uppercase"}>{orderDetail?.shipping_method}</p>
                        <p>{orderDetail?.store_province} - {orderDetail?.shipping_province}</p>
                    </div>
                </div>
                <div className={"row justify-center"}>
                    {
                        !!orderDetail?.awb &&
                        <div>
                            <p className={"font-bold text-dark-grey-blue"}>No. Resi</p>
                            <div className={"row items-center space-x-5"}>
                                <p className={"text-dark-blue"}>{orderDetail?.awb}</p>
                                <div className={"row items-center cursor-pointer"} onClick={copyToClipboard}>
                                    <img src={CopyIcon} className={"w-5"}/>
                                    <p className={"font-bold text-dark-grey-blue text-xs"}>Copy</p>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className={"row justify-center"}>
                    {
                        !!orderDetail?.awb &&
                        <button className={'row items-center border px-2 h-8 rounded-md text-water-blue font-bold'}
                                onClick={() => openPrintLabelModal()}>
                            <img src={PrintIcon} className={"w-6"}/>
                            <p>Cetak resi</p>
                        </button>
                    }
                </div>
            </div>
        </div>
    )
}
