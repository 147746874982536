import React from "react";
import {APPROVED, PACKING, PROCESSING} from "../../models/order.model";
import {useDispatch, useSelector} from "react-redux";
import {
    setOrderListToPrint,
    setOrderReload,
    setOrderSelectedRowKeys,
    setProcessingTab
} from "../../reducers/order.reducer";
import {ConfirmModal} from "../../components/confirm.modal";
import {updateOrderStateApi} from "../../api/order.api";
import {SuccessModal} from "../../components/success.modal";
import {setToolsIsDescending, setToolsPage, setToolsPrintLabelModalVisibility} from "../../reducers/tools.reducer";
import PrintSvg from "../../images/raw-assets/Group 1951-white.svg";

const ProcessingTabComponent = () => {
    const dispatch = useDispatch()
    const {processingTab, selectedRowKeys, reload, selectedRows} = useSelector(state => state.orderReducer)
    const tabList = [
        {
            label: 'Semua',
            key: null
        }, {
            label: 'Perlu Diproses',
            key: PROCESSING
        }, {
            label: 'Siap Diproses',
            key: APPROVED
        }, {
            label: 'Telah Diproses',
            key: PACKING
        }
    ]

    const approveAllHandler = () => {
        let title = 'Terima Pesanan'
        let content = 'Apakah anda yakin ingin menerima pesanan ini?'
        ConfirmModal({title, content, onOkHandler: () => changeOrderStatus()})
    }

    const changeOrderStatus = () => {
        let requestBody = {
            ids: selectedRowKeys,
            status: APPROVED
        }
        updateOrderStateApi(requestBody)
            .then(response => {
                dispatch(setOrderReload(!reload))
                dispatch(setOrderSelectedRowKeys([]))
                SuccessModal('Semua pesanan berhasil diterima')
            })
            .catch(err => {
                console.log(err)
            })
    }

    const onChangeTab = (value) => {
        if (value === PACKING) {
            dispatch(setToolsIsDescending(true))
        } else {
            dispatch(setToolsIsDescending(false))
        }
        dispatch(setToolsPage(1))
        dispatch(setProcessingTab(value))
        dispatch(setOrderSelectedRowKeys([]))
    }

    const bulkPrintLabelHandler = () => {
        dispatch(setOrderListToPrint(selectedRows))
        dispatch(setToolsPrintLabelModalVisibility(true))
    }

    return (
        <div
            className={"row text-sm font-Poppins-Bold mt-10"}>
            {
                tabList.map((item, index) => (
                    <div className={"row"} key={index}>
                        <div className={"w-3 border-b-2 border-cloudy-blue-two"}/>
                        <button
                            className={`border-x-2 border-t-2 ${processingTab !== item.key ? 'border-b-2 bg-ice-blue text-dark-blue-grey' : 'bg-white text-water-blue'} px-4 h-9 rounded-t-lg border-cloudy-blue-two`}
                            onClick={() => onChangeTab(item.key)}
                        >
                            {item.label}
                        </button>
                    </div>
                ))
            }
            <div className={"flex-1 space-x-2 border-b-2 border-cloudy-blue-two"}>
                {
                    processingTab === PROCESSING &&
                    <div className={"row justify-end space-x-5"}>
                        <button
                            className={`${!selectedRowKeys?.length ? 'btn-disabled' : 'button-gradient'}  px-7 h-7 rounded-full`}
                            disabled={!selectedRowKeys?.length}
                            onClick={approveAllHandler}>Terima Semua
                        </button>
                    </div>
                }
                {
                    processingTab === APPROVED &&
                    <div className={"row justify-end space-x-5"}>
                        <button
                            className={`${!selectedRowKeys?.length ? 'btn-disabled' : 'button-gradient'}  px-7 h-7 rounded-full text-white row items-center`}
                            disabled={!selectedRowKeys?.length}
                            onClick={bulkPrintLabelHandler}>
                            <img src={PrintSvg} className={"w-7"}/>
                            <p className={"font-Poppins-Bold"}>Print Label</p>
                        </button>
                    </div>
                }
            </div>
        </div>
    )
}

export default ProcessingTabComponent
