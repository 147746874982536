import React from "react";
import {useSelector} from "react-redux";

const StepComponent = () => {
    const {activeStep} = useSelector(state => state.toolsReducer)
    const stepList = ['withdraw', 'bank account', 'checkout']

    return (
        <div className={"row items-center space-x-1 mt-5"}>
            {
                stepList.map((item, index) => (
                    <div className={"row items-center space-x-1 text-xs"} key={index}>
                        {
                            activeStep === index ?
                                <div className={"col justify-center relative"}>
                                    <p className={`capitalize absolute -top-5 -left-5 text-dark-grey-blue font-bold w-20`}>{item}</p>
                                    <div
                                        className={"box-border h-3 w-3 border flex items-center justify-center border-water-blue rounded-full"}>
                                        <div className={"w-1.5 h-1.5 bg-water-blue rounded-full"}/>
                                    </div>
                                </div>
                                :
                                <div className={"col justify-center relative"}>
                                    <p className={"capitalize absolute -top-5 -left-10 w-20 text-center"}>{item}</p>
                                    <div className={`w-2 h-2 ${ index < activeStep ? 'bg-water-blue' : 'bg-light-grey-blue'} rounded-full`}/>
                                </div>
                        }
                        {
                            index !== 2 &&
                            <div className={"w-24"}>
                                {
                                    index < activeStep ?
                                        <div className={"w-full h-0.5 bg-water-blue"}/> :
                                        <div className={`grid grid-cols-12 gap-1`}>
                                            {
                                                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(i => (
                                                    <span className={"w-1.5 h-0.5 bg-light-grey-blue"} key={i}/>
                                                ))
                                            }
                                        </div>
                                }
                            </div>
                        }
                    </div>
                ))
            }
        </div>
    )
}

export default StepComponent
