import React, {useEffect} from "react";
import SearchIcon from "../images/raw-assets/search-icon.svg";
import {useDispatch} from "react-redux";
import {setToolsPage, setToolsSearchKey} from "../reducers/tools.reducer";

const SearchComponent = (props) => {
    const {placeholder} = props
    const dispatch = useDispatch()

    useEffect(() => {
        return () => {
            dispatch(setToolsSearchKey(''))
        }
    }, [])

    const searchHandler = (value) => {
        dispatch(setToolsPage(1))
        dispatch(setToolsSearchKey(value))
    }

    return (
        <div
            className={`button-rounded-shadow justify-between bg-ice-blue height-resp px-1 l:w-52 xl:w-60 rounded-full border-light-grey-blue capitalize`}>
            <img className={"h-4 w-10"} src={SearchIcon} alt={'search'}/>
            <input
                name={"searchKey"}
                placeholder={placeholder}
                className={"border-0 outline-0 bg-ice-blue rounded-xl px-6 font-size-resp w-full"}
                onChange={(e) => searchHandler(e.target.value)}
            />
        </div>
    )
}

export default SearchComponent
