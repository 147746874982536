import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import ProfileComponent from "./profile.component";
import {
    setToolsBankModalVisibility,
    setToolsCreateStoreModalVisibility,
    setToolsEditProfileModalVisibility
} from "../../reducers/tools.reducer";
import EditProfileModal from "./edit-profile.modal";
import StoreComponent from "./store.component";
import CreateStoreModal from "./create-store.modal";
import BankComponent from "./bank.component";
import {setStoreDetail} from "../../reducers/store.reducer";
import BankModal from "./bank-modal/bank.modal";
import ChangePasswordModal from "./change-password.modal";

export const SettingsComponent = () => {
    const dispatch = useDispatch()
    const {adminDetail} = useSelector(state => state.userAdminReducer)
    const {
        editProfileModalVisibility,
        createStoreModalVisibility,
        bankModalVisibility,
        changePasswordModalVisibility
    } = useSelector(state => state.toolsReducer)
    const [selectedTab, setSelectedTab] = useState({
        label: 'Profile',
        key: 'profile'
    })

    const contentObject = {
        profile: <ProfileComponent/>,
        store: <StoreComponent/>,
        bank_account: <BankComponent/>
    }

    const buttonObject = () => {
        switch (selectedTab?.key) {
            case "profile":
                return (
                    <button className={"button-gradient px-5 py-2 rounded-xl"} onClick={editProfileHandler}>Edit
                        Profile
                    </button>
                )
            case "store":
                return (
                    <button className={"button-gradient px-5 py-2 rounded-xl"} onClick={createStoreOpenModal}>
                        Tambah Toko
                    </button>
                )
            case "bank_account":
                return (
                    <button className={"button-gradient px-5 py-2 rounded-xl"} onClick={createBankHandler}>
                        Tambah Akun Bank
                    </button>
                )
        }
    }

    const tabList = [
        {
            label: 'Profile',
            key: 'profile'
        }, {
            label: 'Bank Account',
            key: 'bank_account'
        }, {
            label: 'Store',
            key: 'store'
        }
    ]

    const tabOnClickHandler = (item) => {
        setSelectedTab(item)
    }

    const createStoreOpenModal = () => {
        dispatch(setStoreDetail({}))
        dispatch(setToolsCreateStoreModalVisibility('block'))
    }

    const editProfileHandler = () => {
        dispatch(setToolsEditProfileModalVisibility('block'))
    }

    const createBankHandler = () => {
        dispatch(setToolsBankModalVisibility('block'))
    }

    return (
        <div className={"card-container font-Poppins-Bold"}>
            <div className={"space-y-7"}>
                <div className={"row items-center space-x-10"}>
                    {
                        tabList.map((item, index) =>
                            <button
                                key={index}
                                className={`${selectedTab.key === item.key ? 'text-water-blue border-b-2 border-water-blue' : 'text-dark-blue-grey'} p-2 hover:bg-ice-blue-two hover:rounded-t-lg`}
                                onClick={() => tabOnClickHandler(item)}
                            >{item.label}</button>
                        )
                    }
                </div>
                <div className={"row items-center justify-between border-b pb-5 "}>
                    <div className={"col space-y-3"}>
                        <p className={"text-sm text-dark-blue-grey"}>Nama Seller</p>
                        <p className={"text-2xl capitalize text-dark-blue-grey"}>{adminDetail?.name}</p>
                    </div>
                    {buttonObject()}
                </div>
                {contentObject[selectedTab.key]}
            </div>
            {editProfileModalVisibility === 'block' && <EditProfileModal/>}
            {createStoreModalVisibility === 'block' && <CreateStoreModal/>}
            {bankModalVisibility === 'block' && <BankModal/>}
            {changePasswordModalVisibility === 'block' && <ChangePasswordModal/>}
        </div>
    )
}
