import UserIcon from "../../images/raw-assets/Group 1946.svg";
import {useSelector} from "react-redux";
import React from "react";
import {convertDateToCustomFormat, statusText} from "../../ui-utils/general-variable";

export default function OrderDetailHeaderComponent(){
    const {orderDetail} = useSelector(state => state.orderReducer)

    return(
        <div className={"col mb-10"}>
            <div className={'row justify-between items-center border-b pb-2 mb-5'}>
                <div className={'row items-center space-x-4 capitalize font-bold'}>
                    <img src={UserIcon} className={'w-8'}/>
                    <p className={"text-dark-grey-blue"}>{orderDetail?.receiver}</p>
                </div>
                <p>{convertDateToCustomFormat(orderDetail?.created_at, 'LLL')}</p>
            </div>
            <div className={'grid grid-cols-2 gap-5 text-sm'}>
                <div className={'row space-x-10'}>
                    <div className={"col font-bold space-y-1"}>
                        <p className={"text-dark-grey-blue"}>Order ID</p>
                        <p className={"italic text-water-blue"}>{orderDetail?.id}</p>
                    </div>
                    <div className={"col font-bold space-y-1"}>
                        <p className={"text-dark-grey-blue"}>Status</p>
                        <p className={"text-light-orange"}>{statusText(orderDetail?.status)}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
