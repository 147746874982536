import React from "react";
import {useSelector} from "react-redux";

const TotalDataComponent = ({slug}) => {
    const {totalData} = useSelector(state => state.toolsReducer)
    const {userBankList} = useSelector(state => state.userReducer)

    return (
        <div className={"row items-center space-x-1 italic text-sm my-2 text-gray-400 capitalize font-open-sans"}>
            <p>total </p>
            <p className={"font-bold"}>{slug === 'akun bank' ? userBankList?.length : totalData} data</p>
            <p>{slug}</p>
        </div>
    )
}

export default TotalDataComponent
