import React from "react";
import {DatePicker} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {setToolsEndDate, setToolsStartDate} from "../reducers/tools.reducer";
import moment from "moment";

const {RangePicker} = DatePicker;

const DatePickerComponent = () => {
    const dispatch = useDispatch()
    const {startDate, endDate} = useSelector(state => state.toolsReducer)

    return (
        <RangePicker className={'button-rounded-shadow'} style={{borderRadius: 8}}
                     size={"small"}
                     value={[moment(startDate), moment(endDate)]}
                     onChange={(date) => {
                         dispatch(setToolsStartDate(moment(date[0]).startOf('day').format()))
                         dispatch(setToolsEndDate(moment(date[1]).endOf('day').format()))
                     }}
        />
    )
}

export default DatePickerComponent
