import {createSlice} from '@reduxjs/toolkit'

export const orderSlice = createSlice({
    name: 'order',
    initialState: {
        orderList: [],
        activeTab: null,
        processingTab: null,
        statusSelected: null,
        sellerList: [],
        sellerSelected: {},
        selectedRowKeys: [],
        selectedRows: [],
        loading: false,
        reload: false,
        orderDetail: {},
        orderHistoryList: [],
        trackingCourier: [],
        orderListToPrint: []
    },
    reducers: {
        setOrderList: (state, action) => {
            state.orderList = action.payload
        },
        setActiveTab: (state, action) => {
            state.activeTab = action.payload
        },
        setOrderStatusSelected: (state, action) => {
            state.statusSelected = action.payload
        },
        setOrderSellerList: (state, action) => {
            state.sellerList = action.payload
        },
        setOrderSellerSelected: (state, action) => {
            state.sellerSelected = action.payload
        },
        setOrderSelectedRowKeys: (state, action) => {
            state.selectedRowKeys = action.payload
        },
        setOrderSelectedRows: (state, action) => {
            state.selectedRows = action.payload
        },
        setOrderLoading: (state, action) => {
            state.loading = action.payload
        },
        setOrderReload: (state, action) => {
            state.reload = action.payload
        },
        setOrderDetail: (state, action) => {
            state.orderDetail = action.payload
        },
        setOrderHistoryList: (state, action) => {
            state.orderHistoryList = action.payload
        },
        setProcessingTab: (state, action) => {
            state.processingTab = action.payload
        },
        setOrderListToPrint: (state, action) => {
            state.orderListToPrint = action.payload
        },
        setOrderTrackingCourier: (state, action) => {
            state.trackingCourier = action.payload
        },
    },
})

export const {
    setOrderList,
    setActiveTab,
    setOrderStatusSelected,
    setOrderSellerList,
    setOrderSellerSelected,
    setOrderTrackingCourier,
    setOrderSelectedRowKeys,
    setOrderLoading,
    setOrderReload,
    setOrderDetail,
    setOrderHistoryList,
    setProcessingTab,
    setOrderSelectedRows,
    setOrderListToPrint
} = orderSlice.actions

const orderReducer = orderSlice.reducer

export default orderReducer
