import React, {useState} from "react";
import TotalDataComponent from "../../components/total-data.component";

const TabComponent = () => {
    const [activeTab, setActiveTab] = useState({
        key: 'all',
        label: 'All Request'
    })
    const tabList = [
        {
            key: 'all',
            label: 'All Request'
        }, {
            key: 'pending',
            label: 'Pending'
        }
    ]

    const onClickHandler = (item) => {
        setActiveTab(item)
    }

    return (
        <div className={"row items-center space-x-5"}>
            {
                tabList.map((item, index) => (
                    <button
                        key={index}
                        onClick={() => onClickHandler(item)}
                        className={`${activeTab.key === item.key && 'text-water-blue border-b-2 border-water-blue'} text-sm pb-2 font-Poppins-Bold`}>
                        {item.label}
                    </button>
                ))
            }
        </div>
    )
}

export default TabComponent
