import React from "react";
import LBILogo from "../../images/png/lbi-logo.png"
import {Form, Input} from "antd";
import {forgotPasswordApi} from "../../api/auth.api";
import {SuccessModal} from "../../components/success.modal";
import ForgotPasswordLogo from "../../images/png/Forgot Password Key.png"

export const ForgotPasswordComponent = () => {

    const onSubmitHandler = (values) => {
        forgotPasswordApi(values)
            .then(response => {
                SuccessModal('Email berhasil dikirim!. Silahkan cek email anda!')
                window.location.href = '/'
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <div
            className={"bg-gradient-to-b from-white to-light-periwinkle col items-center w-full h-full py-32"}>
            <div className={"w-3/5 font-Poppins-Regular col space-y-20"}>
                <img src={LBILogo} className={"w-24 ml-24"}/>
                <div className={"row items-center justify-between"}>
                    <div className={"w-1/4"}>
                        <img src={ForgotPasswordLogo}/>
                    </div>
                    <div className={"rounded-xl shadow-xl bg-white col items-center space-y-5 w-1/3 p-5"}>
                        <p className={"font-Poppins-Bold text-lg"}>Forgot Password</p>
                        <p className={"text-sm"}>Masukkan email anda yang terdaftar sebagai akun di LBI Sociocommerce.
                            Kami
                            akan mengirimkan link ubah password ke email anda</p>
                        <div className={"w-full"}>
                            <Form
                                name="basic"
                                onFinish={onSubmitHandler}
                                layout={"vertical"}
                            >
                                <Form.Item
                                    label={<p className={"font-semibold text-sm"}>Email</p>}
                                    name="email"
                                    rules={[{required: true, message: 'Please input your email!'}]}
                                >
                                    <Input
                                        className={"rounded-full border-light-grey-blue bg-ice-blue outline-none italic h-8 text-sm"}
                                        placeholder={"nama@gmail.com"}/>
                                </Form.Item>
                                <Form.Item>
                                    <button
                                        className={"bg-gradient-to-b from-sky-blue to-water-blue rounded-full text-white w-full h-8 mt-3 font-semibold"}>
                                        Submit
                                    </button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
