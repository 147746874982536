import React, {useEffect} from "react";
import {getStoreListApi} from "../../api/store.api";
import {useDispatch, useSelector} from "react-redux";
import EditSvg from "../../images/raw-assets/Group 2130.svg"
import {setStoreDetail, setStoreList} from "../../reducers/store.reducer";
import {Table} from "antd";
import {setToolsCreateStoreModalVisibility} from "../../reducers/tools.reducer";

const StoreComponent = () => {
    const dispatch = useDispatch()
    const {reload, storeList} = useSelector(state => state.storeReducer)
    const columns = [
        {
            title: 'Toko',
            key: 'store',
            className: "text-sm",
            render: (record) => <p className={"font-Poppins-Bold text-dark-blue-grey capitalize"}>{record.name}</p>
        }, {
            title: 'Alamat',
            key: 'address',
            width: 350,
            className: "text-sm text-dark-grey-blue",
            render: (record) => <p
                className={"capitalize"}>{record?.address}, {record?.district}, {record?.city}, {record?.province},
                ({record?.postal_code})</p>
        }, {
            title: 'Area',
            key: 'area',
            className: "text-sm text-dark-grey-blue",
            render: (record) => <p
                className={"capitalize"}>{record?.city}, {record?.province}</p>
        }, {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            className: "text-sm",
            render: (status) => <p
                className={`font-Poppins-Bold ${status === 'ACTIVE' ? 'text-dark-seafoam' : 'text-dusty-red'}`}>{status === 'ACTIVE' ? 'Buka' : 'Tutup'}</p>
        }, {
            title: 'Action',
            key: 'action',
            render: (record) => <img className={"w-8 cursor-pointer"} src={EditSvg}
                                     onClick={() => updateStoreOpenModal(record)}/>
        }
    ]

    useEffect(() => {
        getStoreListApi()
            .then(result => {
                dispatch(setStoreList(result))
            })
            .catch(err => {
                console.log('Error getStoreListBySellerIdApi', err)
            })
    }, [reload])

    const updateStoreOpenModal = (record) => {
        dispatch(setStoreDetail(record))
        dispatch(setToolsCreateStoreModalVisibility('block'))
    }

    return (
        <div className={"font-Poppins-Regular"}>
            <Table rowKey={'id'} dataSource={storeList} columns={columns}/>
        </div>
    )
}

export default StoreComponent
