import React, {useRef} from "react";
import {CloseOutlined} from "@ant-design/icons";
import {setToolsChangePasswordModalVisibility} from "../../reducers/tools.reducer";
import {useDispatch, useSelector} from "react-redux";
import {Form, Input} from "antd";
import FormItemComponent from "../../components/form-item.component";
import {updateSellerProfileApi} from "../../api/auth.api";
import {SuccessModal} from "../../components/success.modal";

const ChangePasswordModal = () => {
    const formRef = useRef();
    const dispatch = useDispatch()
    const {changePasswordModalVisibility} = useSelector(state => state.toolsReducer)

    const closeModal = () => {
        dispatch(setToolsChangePasswordModalVisibility('hidden'))
    }

    const onFinish = (values) => {
        updateSellerProfileApi(values)
            .then(result => {
                closeModal()
                SuccessModal('Password berhasil di update!')
            })
            .catch(err => console.log(err))
    }

    return (
        <div
            className={`${changePasswordModalVisibility} absolute fixed flex left-0 top-0 w-full h-full justify-center items-center bg-gray-400 bg-opacity-30 z-20`}>
            <div className="col w-2/6 max-h-60 bg-white p-5 z-10 rounded-lg shadow-2xl font-Poppins-Bold space-y-5">
                <div className={"flex justify-end"}>
                    <CloseOutlined onClick={closeModal}/>
                </div>
                <p className={"font-Poppins-Bold text-center"}>Ubah Password</p>
                <div className={"col items-center"}>
                    <Form
                        ref={formRef}
                        layout="vertical"
                        className={"text-sm"}
                        onFinish={onFinish}
                    >
                        <FormItemComponent
                            item={{
                                name: "password",
                                label: "Masukkan Password Baru",
                                message: "Masukkan password baru"
                            }}
                            content={
                                <Input.Password
                                    style={{
                                        width: 400,
                                        height: 40,
                                        borderRadius: 12
                                    }} placeholder={"Masukkan password baru"}/>
                            }
                        />
                        <Form.Item>
                            <div className={"flex justify-center"}>
                                <button className={"button-gradient px-5 py-2 rounded-full"}>
                                    Submit
                                </button>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default ChangePasswordModal
