import React, {useState, useEffect} from "react";
import ArrowDownSvg from "../images/raw-assets/arrow-down.svg";
import {statusText} from "../ui-utils/general-variable";
import {setToolsLimit, setToolsPage} from "../reducers/tools.reducer";
import {setOrderStatusSelected} from "../reducers/order.reducer";
import {useDispatch} from "react-redux";
import {setStatusSelected} from "../reducers/product.reducer";

const SelectComponent = (props) => {
    const dispatch = useDispatch()
    const [visibility, setVisibility] = useState(false)
    const {options, title, slug} = props

    useEffect(() => {
        return () => {
            dispatch(setToolsLimit(10))
        }
    }, [])

    const onClickHandler = (item) => {
        let returnItem = {}
        switch (slug){
            case 'limit':
                returnItem = dispatch(setToolsLimit(item))
                break;
            case 'status':
                returnItem =  dispatch(setOrderStatusSelected(item))
                break;
            case 'product':
                returnItem =  dispatch(setStatusSelected(item))
                break;
        }

        return returnItem
    }

    const onClickHelper = () => {
        setVisibility(false)
        if(slug === 'limit'){
            return dispatch(setToolsPage(1))
        }
    }

    return (
        <div className={"relative"}>
            <button className={'button-rounded-shadow justify-between space-x-4 px-4 min-w-56'}
                    onClick={() => setVisibility(!visibility)}
            >
                <p>{title}</p>
                <img src={ArrowDownSvg} className={"l:w-2 xl:w-3"}/>
            </button>
            <div
                className={` ${visibility ? 'visible' : 'invisible'} absolute border mt-3 p-2 bg-white z-10 rounded-lg shadow-lg space-y-5 w-32`}>
                {
                    options.map((item, index) => (
                        <p key={index} className={"cursor-pointer"} onClick={() => {
                            onClickHandler(item)
                            onClickHelper()
                        }}>{slug === 'status' ? statusText(item) : item}</p>
                    ))
                }
            </div>
        </div>
    )
}

export default SelectComponent
