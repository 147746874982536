import './App.css';
import {BrowserRouter} from "react-router-dom";
import MainDashboardComponent from "./components/main.component";
import RoutePage from "./routes";
import {Provider} from "react-redux";
import store from "./reducers";

function App() {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <MainDashboardComponent content={<RoutePage/>}/>
            </BrowserRouter>
        </Provider>
    );
}

export default App;
