import React from "react";
import {loginApi} from "../../api/auth.api";
import {Form, Input} from "antd";
import LBILogo from "../../images/png/lbi-logo.png"
import {Link} from "react-router-dom";

export default function AuthPage() {

    const onSubmitHandler = (values) => {
        let body = {
            email: values.email,
            password: values.password
        }

        if (body.email && body.password) {
            loginApi(body)
                .then(result => {
                    if (result.status === 'Successful') {
                        localStorage.setItem('LBI_SELLER_TOKEN', result.token)
                        window.location.reload()
                    } else {
                        alert('Maaf anda tidak memiliki akses!!')
                    }
                })
                .catch(err => {
                    console.log("err login :", err.data)
                    alert(err.data.message)
                })
        }
    }

    return (
        <div
            className={"col items-center w-full h-full bg-gradient-to-b from-white to-light-periwinkle pt-28"}>
            <img src={LBILogo} className={"mb-16"}/>
            <div className={"w-1/5 col space-y-5 bg-white p-5 rounded-xl shadow-xl font-open-sans"}>
                <p className={"text-center text-2xl"}>Log In</p>
                <Form
                    name="basic"
                    initialValues={{remember: true}}
                    onFinish={onSubmitHandler}
                    autoComplete="off"
                    layout={"vertical"}
                >
                    <Form.Item
                        label={<p className={"font-semibold text-sm"}>Username / Email</p>}
                        name="email"
                        rules={[{required: true, message: 'Please input your email!'}]}
                    >
                        <Input className={"rounded-full border-light-grey-blue bg-ice-blue outline-none"}/>
                    </Form.Item>

                    <Form.Item
                        label={<p className={"font-semibold text-sm"}>Password</p>}
                        name="password"
                        rules={[{required: true, message: 'Please input your password!'}]}
                    >
                        <Input.Password className={"rounded-full border-light-grey-blue bg-ice-blue outline-none"}/>
                    </Form.Item>
                    <Link to={`/forgot-password`}>
                        <p className={"row justify-end font-OpenSans-Bold text-sm my-3"}>Forgot Password ?</p>
                    </Link>
                    <Form.Item>
                        <button
                            className={"bg-gradient-to-b from-sky-blue to-water-blue rounded-full text-white w-full h-8"}>
                            Log In
                        </button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}
