import {createSlice} from '@reduxjs/toolkit'
import moment from "moment";

const INITIAL_STATE = {
    options: [],
    limit: 10,
    page: 1,
    totalData: 0,
    activeStep: 0,
    isDescending: true,
    startDate: moment().subtract(30, 'days').startOf('day').format(),
    endDate: moment().endOf('day').format(),
    searchKey: '',
    printLabelModalVisibility: 'invisible',
    editProfileModalVisibility: 'hidden',
    createStoreModalVisibility: 'hidden',
    changePasswordModalVisibility: 'hidden',
    bankModalVisibility: 'hidden',
    createPayoutModalVisibility: 'hidden'
}

//reducer for page filter setting, e.g: limit, options, sort.
export const toolsSlice = createSlice({
    name: 'tools',
    initialState: {...INITIAL_STATE},
    reducers: {
        setToolsLimit: (state, action) => {
            state.limit = action.payload
        },
        setToolsOptions: (state, action) => {
            state.options = action.payload
        },
        setToolsTotalData: (state, action) => {
            state.totalData = action.payload
        },
        setToolsPage: (state, action) => {
            state.page = action.payload
        },
        setToolsStartDate: (state, action) => {
            state.startDate = action.payload
        },
        setToolsEndDate: (state, action) => {
            state.endDate = action.payload
        },
        setToolsSearchKey: (state, action) => {
            state.searchKey = action.payload
        },
        setToolsIsDescending: (state, action) => {
            state.isDescending = action.payload
        },
        setToolsPrintLabelModalVisibility: (state, action) => {
            state.printLabelModalVisibility = action.payload
        },
        setToolsEditProfileModalVisibility: (state, action) => {
            state.editProfileModalVisibility = action.payload
        },
        setToolsCreateStoreModalVisibility: (state, action) => {
            state.createStoreModalVisibility = action.payload
        },
        setToolsBankModalVisibility: (state, action) => {
            state.bankModalVisibility = action.payload
        },
        setToolsChangePasswordModalVisibility: (state, action) => {
            state.changePasswordModalVisibility = action.payload
        },
        setCreatePayoutModalVisibility: (state, action) => {
            state.createPayoutModalVisibility = action.payload
        },
        setCreatePayoutModalVisibility: (state, action) => {
            state.createPayoutModalVisibility = action.payload
        },
        setToolsActiveStep: (state, action) => {
            state.activeStep = action.payload
        },
        setToolsReset: (state) => {
            state.limit = 10
            state.searchKey = ''
            state.page = 1
            state.isDescending = true
            state.startDate = moment().subtract(30, 'days').startOf('day').format()
            state.endDate = moment().endOf('day').format()
        },
        resetState : () => INITIAL_STATE
    },
})

export const {
    setToolsLimit,
    setToolsPage,
    setToolsStartDate,
    setToolsEndDate,
    setToolsSearchKey,
    setToolsIsDescending,
    setToolsTotalData,
    setToolsOptions,
    setToolsPrintLabelModalVisibility,
    setToolsEditProfileModalVisibility,
    setToolsCreateStoreModalVisibility,
    setToolsBankModalVisibility,
    setToolsChangePasswordModalVisibility,
    setCreatePayoutModalVisibility,
    setToolsActiveStep,
    resetState,
    setToolsReset
} = toolsSlice.actions

const toolsReducer = toolsSlice.reducer

export default toolsReducer
