import axios from "axios";
import {baseUrl} from "./api.config";
import {authToken} from "../ui-utils/general-variable";

const uploadImageApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${baseUrl()}/api/v1/file/image`,
            requestBody,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'multipart/form-data',
                    'Authorization': `Bearer ${authToken}`
                }
            })
            .then(response => {
                resolve(response.data.data)
            })
            .catch(err => {
                console.log("err uploadImageApi :", err.response)
                reject(err)
            })
    })
}

// var axios = require('axios');
// var FormData = require('form-data');
// var fs = require('fs');
// var data = new FormData();
// data.append('image', fs.createReadStream('/path/to/file'));
// data.append('folder', '');
//
// var config = {
//     method: 'post',
//     url: 'http://localhost:8000/api/v1/file/image',
//     headers: {
//         'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjUwOTQ0MjMxfQ.4FiOqoA19FA0GfpZapE3esDNlqcf2XmkgyKeD3oxM58',
//         ...data.getHeaders()
//     },
//     data : data
// };
//
// axios(config)
//     .then(function (response) {
//         console.log(JSON.stringify(response.data));
//     })
//     .catch(function (error) {
//         console.log(error);

export {
    uploadImageApi
}
