import React, {useState} from "react";
import {Button, Form, InputNumber, Select, Table} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {doConvertNumberToRupiahFormat} from "../../ui-utils/general-variable";
import {setStoreId} from "../../reducers/store.reducer";
import {updateProductApi} from "../../api/product.api";
import {SuccessModal} from "../../components/success.modal";
import {setProductReload} from "../../reducers/product.reducer";

const {Option} = Select;

const VariantComponent = () => {
    const dispatch = useDispatch()
    const [form] = Form.useForm();
    const {storeList, storeId} = useSelector(state => state.storeReducer)
    const {productDetail, reload} = useSelector(state => state.productReducer)
    const [stock, setStock] = useState(0)
    const [editingKey, setEditingKey] = useState('')
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            render: (price) => doConvertNumberToRupiahFormat(price)
        },
        {
            title: 'Stock',
            dataIndex: 'stock',
            key: 'stock',
            width: 100,
            editable: true,
        },
        {
            title: 'Action',
            key: 'action',
            render: (record) => (
                <div className={'row space-x-2'}>
                    {
                        editingKey === record?.id ?
                            <Button shape={'round'} onClick={() => save(record?.id)}>Save</Button> :
                            <Button shape={'round'} onClick={() => {
                                setEditingKey(record?.id)
                                form.setFieldsValue({
                                    [record?.id]: record?.stock
                                });
                            }}>Edit</Button>
                    }
                </div>
            )
        },
    ]

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record, index) => ({
                record,
                index,
                dataIndex: col.dataIndex,
                title: col.title,
            }),
        };
    });

    const save = (id) => {
        let requestBody = {
            store_id: storeId,
            items: [{
                variant_id: id,
                stock: form.getFieldValue(id)
            }]
        }

        updateProductApi(requestBody)
            .then(response => {
                setEditingKey('')
                dispatch(setProductReload(!reload))
                SuccessModal('Stock berhasil di update')
            })
            .catch(err => {
                console.log('Error updateProductApi', err)
            })
    }

    const EditableCell = ({dataIndex, title, record, index, children, ...restProps}) => {
        return (
            <td {...restProps}>
                {editingKey === record?.id ? (
                    <Form.Item name={record.id} className={"w-20"}>
                        <InputNumber/>
                    </Form.Item>
                ) : children}
            </td>
        );
    };

    const handleChange = (value) => {
        dispatch(setStoreId(value))
    };

    return (
        <div className={"mt-10 text-sm"}>
            <div className={"row items-center space-x-5 mb-4"}>
                <p>Pilih Store :</p>
                <Select defaultValue={storeId} style={{width: 120, fontSize: 12}} onChange={handleChange}>
                    {
                        storeList?.length > 0 &&
                        storeList.map((item, index) => <Option key={index} value={item?.id}
                                                               style={{fontSize: 10}}>{item?.name}</Option>)
                    }
                </Select>
            </div>
            <Form form={form} component={false}>
                <Table
                    components={{body: {cell: EditableCell}}}
                    bordered
                    dataSource={productDetail?.variants}
                    columns={mergedColumns}
                    rowKey={'id'}
                    rowClassName="editable-row"
                    pagination={false}
                />
            </Form>
        </div>
    )
}

export default VariantComponent
