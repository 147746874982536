import React from "react";
import SearchComponent from "../../../components/search.component";
import {setSelectedBank} from "../../../reducers/bank.reducer";
import {filter} from "lodash";
import {useDispatch, useSelector} from "react-redux";

const ListBankComponent = () => {
    const dispatch = useDispatch()
    const {searchKey} = useSelector(state => state.toolsReducer)
    const {selectedBank, bankList} = useSelector(state => state.bankReducer)

    const bankItemClickHandler = (item) => {
        dispatch(setSelectedBank(item))
    }

    const defineBankList = () => {
        let newBankList = filter(bankList, item => item?.name.toLowerCase().includes(searchKey.toLowerCase()))
        return newBankList
    }
    return (
        <div className={"col items-center"}>
            <p className={"text-center my-3"}>Pilih Bank</p>
            <SearchComponent placeholder={"Cari bank"}/>
            <div className={"col space-y-3 overflow-auto max-h-20 my-3 max-w-fit"}>
                {
                    defineBankList().map((item, index) => (
                        <div
                            className={`row items-center space-x-5 border px-3 py-2 rounded-lg cursor-pointer ${selectedBank === item && 'bg-ice-blue-four border-water-blue'}`}
                            key={index}
                            onClick={() => bankItemClickHandler(item)}
                        >
                            <input type={"radio"} className={"accent-water-blue"} name={"bank"}
                                   checked={selectedBank === item} onChange={() => null}/>
                            <p>{item?.name}</p>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default ListBankComponent
