const baseUrl = () => {
    return process.env.REACT_APP_BASE_URL
}

const token = localStorage.getItem('LBI_SELLER_TOKEN');

const DEFAULT_API_HEADER = {
    'Accept': 'application/json',
    'Content-type': 'application/json'
};

export {
    baseUrl,
    DEFAULT_API_HEADER,
    token
}
