import {combineReducers, configureStore} from "@reduxjs/toolkit";
import userAdminReducer from "./admin.reducer";
import productReducer from "./product.reducer";
import orderReducer from "./order.reducer";
import toolsReducer from "./tools.reducer";
import storeReducer from "./store.reducer";
import bankReducer from "./bank.reducer";
import userReducer from "./user.reducer";

const rootReducer = combineReducers({
    userAdminReducer,
    productReducer,
    orderReducer,
    toolsReducer,
    storeReducer,
    bankReducer,
    userReducer
})

const store = configureStore({reducer: rootReducer})

export default store
