import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getProductListApi} from "../../api/product.api";
import FilterComponent from "./filter.component";
import ProductListComponent from "./product-list.component";
import {PUBLISHED} from "../../models/product.model";
import {setProductList} from "../../reducers/product.reducer";
import {setToolsPage, setToolsTotalData} from "../../reducers/tools.reducer";

export const ProductsComponent = () => {
    const dispatch = useDispatch()
    const {isDescending, searchKey, limit, page} = useSelector(state => state.toolsReducer)

    useEffect(() => {
        return () => {
            dispatch(setToolsPage(1))
        }
    }, [])

    useEffect(() => {
        let params = {
            status: PUBLISHED,
            sort: isDescending ? 'created_at-desc' : 'created_at-asc',
            search: searchKey,
            limit,
            page
        }

        getProductListApi(params)
            .then(result => {
                dispatch(setToolsTotalData(result.count))
                dispatch(setProductList(result.rows))
            })
            .catch(err => {
                console.log("err get list product :", err)
            })
    }, [isDescending, searchKey, page])

    return (
        <div className={"card-container text-sm col space-y-5 font-Poppins-Regular"}>
            <FilterComponent/>
            <ProductListComponent/>
        </div>
    )
}
