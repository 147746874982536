import React, {useState} from "react";
import _ from "lodash";
import {useDispatch, useSelector} from "react-redux";
import {setActiveTab, setProcessingTab} from "../../reducers/order.reducer";
import {COMPLETED, DELIVERY, PACKING, PROCESSING} from "../../models/order.model";
import {setToolsIsDescending, setToolsPage} from "../../reducers/tools.reducer";

const OrderTabComponent = () => {
    const dispatch = useDispatch()
    const {activeTab, processingTab} = useSelector(state => state.orderReducer)
    const [menu] = useState([
        {id: 1, name: 'semua', key: null},
        {id: 2, name: 'perlu dikirim', key: PROCESSING},
        {id: 3, name: 'dikemas', key: PACKING},
        {id: 4, name: 'dikirim', key: DELIVERY},
        {id: 5, name: 'selesai', key: COMPLETED}
    ])

    const onClickHandler = (item) => {
        if(item.key === PROCESSING){
            dispatch(setToolsIsDescending(false))
        } else {
            dispatch(setToolsIsDescending(true))
        }
        if(processingTab !== null){
            dispatch(setProcessingTab(null))
        }
        dispatch(setToolsPage(1))
        dispatch(setActiveTab(item.key))
    }

  return (
      <div>
          <div className="bg-white mb-8">
              <nav className="flex flex-row  items-center md:text-xl">
                  {
                      _.map(menu, item => {
                          return (
                              <button
                                  key={item.id}
                                  onClick={() => onClickHandler(item)}
                                  className={`${activeTab === item.key && 'text-water-blue border-b-2 border-water-blue'} hover:rounded-t-lg hover:bg-ice-blue-two hover:text-water-blue capitalize text-sm py-2 font-Poppins-Bold px-6 block focus:outline-none`}>
                                  {item.name}
                              </button>
                          )
                      })
                  }
              </nav>
          </div>
      </div>
  )
}

export default OrderTabComponent
