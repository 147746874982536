import OrderDetailHeaderComponent from "./order-detail-header.component";
import React, {useEffect} from "react";
import {getHistoryListOrderApi, getOrderDetailApi, getTrackingOrderListApi} from "../../api/order.api";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {setOrderDetail, setOrderHistoryList, setOrderTrackingCourier} from "../../reducers/order.reducer";
import OrderAddressDetailComponent from "./order-address-detail.component";
import OrderCourierDetailComponent from "./order-courier-detail.component";
import OrderProductDetailComponent from "./order-product-detail.component";
import HistoryOrderDetailComponent from "./history-order-detail.component";
import PrintLabelComponent from "../../components/print-label.component";
import TrackingOrderDetailComponent from "./tracking-order-detail.component";

export function OrderDetailComponent() {
    const {id} = useParams();
    const dispatch = useDispatch()
    const {reload, trackingCourier, orderListToPrint} = useSelector(state => state.orderReducer)

    useEffect(() => {
        Promise.all([getHistoryListOrderApi(id), getTrackingOrderListApi(id)])
            .then(result => {
                dispatch(setOrderHistoryList(result[0]))
                dispatch(setOrderTrackingCourier(result[1]))
            })
            .catch(err => console.log(err))
    }, [])

    useEffect(() => {
        getOrderDetailApi(id)
            .then(result => {
                dispatch(setOrderDetail(result))
            })
            .catch(err => console.log(err))
    }, [reload])

    return (
        <>
            <div className={'row space-x-8 font-Poppins-Regular text-sm leading-relaxed'}>
                <div className={'rounded-xl border container p-10 bg-white w-4/5 shadow-xl border border-blue-200'}>
                    <OrderDetailHeaderComponent/>
                    <div className={"col space-y-10"}>
                        <OrderAddressDetailComponent/>
                        <OrderCourierDetailComponent/>
                        <OrderProductDetailComponent/>
                    </div>
                </div>
                <div className={'w-2/5 space-y-10'}>
                    {trackingCourier.length > 0 && <TrackingOrderDetailComponent/>}
                    <HistoryOrderDetailComponent/>
                </div>
            </div>
            {orderListToPrint.length > 0 && <PrintLabelComponent slug={'order-detail'}/>}
        </>
    )
}
