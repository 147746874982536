import React, {useState} from "react";
import SearchComponent from "../../components/search.component";
import SortComponent from "../../components/sort.component";
import DatePickerComponent from "../../components/date-picker.component";
import {useSelector} from "react-redux";
import ExportOrderComponent from "../../components/export-order.component";
import SelectComponent from "../../components/select.component";

const OrderFilterComponent = () => {
    const {limit} = useSelector(state => state.toolsReducer)
    const [limitOptions] = useState([10, 20, 50, 100, 150])

    return (
        <div className={'mb-5 flex items-center capitalize text-tiny justify-between'}>
            <div className={"row items-center l:space-x-2 xl:space-x-4"}>
                <SearchComponent placeholder={"cari pesanan"}/>
                <SortComponent/>
                <DatePickerComponent/>
                <SelectComponent options={limitOptions} slug={'limit'} title={`Tampilkan ${limit} Item`}/>
                <ExportOrderComponent/>
            </div>
        </div>
    )
}

export default OrderFilterComponent
