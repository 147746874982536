import React from "react";
import {Statistic, Table} from "antd";
import {
    convertDateToCustomFormat,
    doConvertNumberToRupiahFormat,
    getCourierImage,
    statusText
} from "../../ui-utils/general-variable";
import {useDispatch, useSelector} from "react-redux";
import IconClock from "../../images/raw-assets/Group 1955.svg";
import PrintSvg from "../../images/raw-assets/Group 1951.svg";
import moment from "moment";
import {
    setOrderListToPrint,
    setOrderReload,
    setOrderSelectedRowKeys,
    setOrderSelectedRows
} from "../../reducers/order.reducer";
import {APPROVED, CANCELLED, COMPLETED, DELIVERY, PACKING, PROCESSING} from "../../models/order.model";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons"
import {rejectOrderApi, updateOrderStateApi} from "../../api/order.api";
import {ConfirmModal} from "../../components/confirm.modal";
import {SuccessModal} from "../../components/success.modal";
import PrintLabelComponent from "../../components/print-label.component";
import {setToolsPage, setToolsPrintLabelModalVisibility} from "../../reducers/tools.reducer";
import {Link} from "react-router-dom";
import TotalDataComponent from "../../components/total-data.component";

const {Countdown} = Statistic;

const OrderListComponent = () => {
    const dispatch = useDispatch()
    const {
        orderList,
        selectedRowKeys,
        loading,
        processingTab,
        reload,
        orderListToPrint
    } = useSelector(state => state.orderReducer)
    const {limit, page, totalData} = useSelector(state => state.toolsReducer)
    const columns = [
        {
            title: 'Produk',
            dataIndex: 'variants',
            className: 'text-sm',
            width: 350,
            key: 'product',
            render: (variants, record) => (
                <Link to={`/orders/order-list/order-detail/${record.id}`}>
                    {
                        variants.length > 0 &&
                        <div className={'col space-y-2'}>
                            <div className={'row space-x-2'}>
                                <p className={'font-bold text-water-blue'}>{variants[0]?.order_variant.orderId}</p>
                                <p className={' '}>{record?.receiver}</p>
                            </div>
                            <div className={'row space-x-2'}>
                                <img src={variants[0]?.product.product_images[0]?.image_url}
                                     className={'w-12 h-12'}/>
                                <div className={"col space-y-1 text-xs"}>
                                    <p className={'mb-0 font-bold capitalize text-sm text-dark-grey-blue'}>{variants[0]?.product.name}</p>
                                    <div className={"flex flex-row items-center space-x-1 text-dark-blue"}>
                                        <p className={"text-dark-grey-blue"}>{doConvertNumberToRupiahFormat(variants[0]?.order_variant.history_price)}</p>
                                        <p className={"font-bold text-dark-grey-blue"}>x{variants[0]?.order_variant.quantity}</p>
                                    </div>
                                    {variants.length > 1 &&
                                        <p className={"font-bold italic"}>dan {variants.length - 1} produk lainnya</p>}
                                </div>
                            </div>
                        </div>
                    }
                </Link>
            )
        },
        {
            title: 'Grand Total',
            className: 'text-sm',
            dataIndex: 'grand_total',
            key: 'grand_total',
            render: (grand_total) => <p
                className={"font-semibold text-dark-blue"}>{doConvertNumberToRupiahFormat(grand_total)}</p>
        },
        {
            title: 'Courier',
            dataIndex: 'shipping_method',
            className: 'text-sm',
            key: 'shipping_method',
            render: (shipping_method) => <img src={getCourierImage(shipping_method)} className={"w-20"}/>
        },
        {
            title: 'Status',
            dataIndex: 'status',
            className: 'text-sm',
            key: 'status',
            render: (status) => <p
                className={`font-semibold ${defineTextColor(status).color}`}>{statusText(status)}</p>
        },
        {
            title: 'Hitung mundur',
            dataIndex: 'reject_start_time',
            className: 'text-sm',
            key: 'reject_start_time',
            render: (reject_start_time, record) => (
                <div>
                    {
                        !!reject_start_time && record.status === PROCESSING &&
                        <div className={"text-dark-blue"}>
                            <Countdown value={moment(reject_start_time).add(12, 'hours').format()} format="HH:mm:ss"
                                       valueStyle={{fontSize: 14, color: '#db5757', fontWeight: 'bold'}}/>
                            <div>Ditolak Otomatis</div>
                            <div
                                className={"font-bold"}>{moment(reject_start_time).add(12, 'hours').format('DD/MM/YYYY HH:mm')}</div>
                        </div>
                    }
                </div>
            )
        }, {
            title: 'Action',
            className: 'text-sm',
            key: 'action',
            render: (record) =>
                <div className={"col space-y-5"}>
                    <div className={"row items-center"}>
                        <img src={IconClock} className={"w-7"}/>
                        <div>{convertDateToCustomFormat(record.created_at, 'DD/MM/YYYY HH:mm')}</div>
                    </div>
                    {
                        record.status === PROCESSING  &&
                        <div className={"row items-center space-x-5"}>
                            <button
                                className={"row items-center justify-center space-x-1 text-dusty-red font-bold font-Poppins-Bold"}
                                onClick={() => approveRejectButtonHandler(record.id, 'reject')}
                            >
                                <CloseOutlined/>
                                <p>Tolak</p>
                            </button>
                            <button
                                className={"row items-center justify-center space-x-1 text-water-blue font-bold font-Poppins-Bold"}
                                onClick={() => approveRejectButtonHandler(record.id, 'approve')}
                            >
                                <CheckOutlined/>
                                <p>Terima</p>
                            </button>
                        </div>
                    }
                    {
                        record.status === APPROVED &&
                        <button className={"row items-center space-x-2"}
                                onClick={() => openPrintLabelModal(record)}
                        >
                            <img src={PrintSvg} className={"w-7"}/>
                            <p className={"text-water-blue font-Poppins-Bold"}>Print Label</p>
                        </button>
                    }
                </div>

        }
    ]

    const rejectButtonHandler = (id) => {
        rejectOrderApi(id)
            .then(response => {
                dispatch(setOrderReload(!reload))
                SuccessModal('Pesanan berhasil ditolak')
            })
            .catch(err => {
                console.log(err)
            })
    }

    const openPrintLabelModal = (orderDetail) => {
        dispatch(setOrderListToPrint([orderDetail]))
        dispatch(setToolsPrintLabelModalVisibility('visible'))
    }

    const approveRejectButtonHandler = (id, slug) => {
        let title = slug === 'approve' ? 'Terima Pesanan' : 'Tolak Pesanan'
        let content = `Apakah anda yakin ingin ${slug === 'approve' ? 'menerima' : 'menolak'} pesanan ini?`
        ConfirmModal({
            title,
            content,
            onOkHandler: () => slug === 'approve' ? changeOrderStatus(id) : rejectButtonHandler(id)
        })
    }

    const changeOrderStatus = (id) => {
        let requestBody = {
            ids: [id],
            status: APPROVED
        }
        updateOrderStateApi(requestBody)
            .then(response => {
                dispatch(setOrderReload(!reload))
                SuccessModal('Pesanan berhasil diterima')
            })
            .catch(err => {
                console.log(err)
            })
    }

    const defineTextColor = (status) => {
        let textColor = {
            text: '',
            color: '',
            paymentText: ''
        }
        switch (status) {
            case PROCESSING:
                textColor.color = 'text-amber-300'
                break;
            case APPROVED:
                textColor.color = 'text-faded-blue'
                break;
            case PACKING:
                textColor.color = 'text-light-orange'
                break;
            case DELIVERY:
                textColor.color = 'text-amethyst'
                break;
            case COMPLETED:
                textColor.color = 'text-dark-seafoam'
                break;
            case CANCELLED:
                textColor.color = 'text-pastel-red'
                break;
            default:
                textColor.color = 'text-dark-blue'
        }
        return textColor
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            dispatch(setOrderSelectedRowKeys(selectedRowKeys));
            dispatch(setOrderSelectedRows(selectedRows))
        }
    }

    const onChangePagination = (value) => {
        dispatch(setToolsPage(value))
    }

    return (
        <>
            <TotalDataComponent slug={'pesanan'}/>
            <Table
                rowKey={'id'}
                rowSelection={rowSelection}
                className={'font-Poppins-Regular text-sm'}
                dataSource={orderList}
                columns={columns}
                loading={loading}
                pagination={{
                    pageSize: limit,
                    current: page,
                    total: totalData,
                    onChange: onChangePagination,
                }}
            />
            {orderListToPrint.length > 0 && <PrintLabelComponent/>}
        </>
    )
}

export default OrderListComponent
