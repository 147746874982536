import React from "react";
import {Form} from "antd";

const FormItemComponent = (props) => {
    const {item, content} = props
    return (
        <Form.Item
            name={item.name}
            label={<div
                className={"font-bold text-dark-blue-grey"}>{item.label}</div>}
            rules={[{required: item.name !== 'status' ? true : false, message: item.message}]}
        >
            {content}
        </Form.Item>
    )
}

export default FormItemComponent
