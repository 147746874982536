import SellerIcon from "../../images/raw-assets/Group 1948.svg";
import {useSelector} from "react-redux";
import UserIcon from "../../images/raw-assets/Group 1946.svg";

export default function OrderAddressDetailComponent() {
    const {orderDetail} = useSelector(state => state.orderReducer)

    return (
        <div className={'grid grid-cols-2 gap-5'}>
            <div className={"col space-y-3"}>
                <div className={"col space-y-1"}>
                    <p className={'font-bold text-dark-grey-blue'}>Alamat Pengiriman</p>
                    <p className={"text-dark-blue"}>{orderDetail?.shipping_address}</p>
                </div>
                <div className={"col space-y-1"}>
                    <p className={'font-bold text-dark-grey-blue'}>Nomor Telp.</p>
                    <p className={"text-dark-blue"}>{orderDetail?.receiver_phone}</p>
                </div>
                <div>
                    <p className={'font-bold text-dark-grey-blue'}>Catatan Dari Costumer</p>
                    <p className={"text-dark-blue"}>{orderDetail?.note}</p>
                </div>
            </div>
            <div className={"col space-y-3"}>
                <div className={'row items-center space-x-2'}>
                    <img src={SellerIcon} className={'w-6'}/>
                    <p className={'font-bold text-dark-grey-blue'}>Seller</p>
                </div>
                <div className={'row items-center space-x-2 font-bold'}>
                    <img src={UserIcon} className={'w-6'}/>
                    <p className={'font-bold text-dark-grey-blue'}>{orderDetail?.store?.name}</p>
                </div>
                <div className={"ml-8"}>
                    <p className={'font-bold text-dark-grey-blue'}>Alamat Seller</p>
                    <p className={"text-dark-blue"}>{orderDetail?.seller_address}</p>
                </div>
                <div className={"ml-8"}>
                    <p className={'font-bold text-dark-grey-blue'}>Nomor Telp.</p>
                    <p className={"text-dark-blue"}>{orderDetail?.store?.phone}</p>
                </div>
            </div>
        </div>
    )
}
