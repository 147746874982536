import axios from "axios";
import {baseUrl, DEFAULT_API_HEADER, token} from "./api.config";
import {authToken} from "../ui-utils/general-variable";

const loginApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${baseUrl()}/api/v1/auth/seller/login`,
            requestBody,
            {
                headers: {...DEFAULT_API_HEADER}
            })
            .then(response => {
                resolve(response.data)
            })
            .catch(err => {
                reject(err.response)
            })
    })
}

const getUserDetailApi = () => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${baseUrl()}/api/v1/seller/profile`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                }
            })
            .then(response => {
                resolve(response.data)
            })
            .catch(err => {
                console.log("err loginApi :", err.response)
                reject(err)
            })
    })
}

const forgotPasswordApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${baseUrl()}/api/v1/auth/seller/forgot-password`,
            requestBody
        )
            .then(response => resolve(response.data))
            .catch(err => reject(err.response))
    })
}

const updateSellerProfileApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${baseUrl()}/api/v1/seller/profile/update`,
            requestBody,
            {headers: {'Authorization': `Bearer ${token}`}}
        )
            .then(response => resolve(response.data))
            .catch(err => reject(err.response))
    })
}

export {
    loginApi,
    getUserDetailApi,
    forgotPasswordApi,
    updateSellerProfileApi
}
