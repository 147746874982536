import React, {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import BalanceSvg from "../images/raw-assets/Group 1926.svg";
import BalanceWhiteSvg from "../images/raw-assets/Group 1935.svg";
import OrderSvg from "../images/raw-assets/Group 1930.svg";
import ProductSvg from "../images/raw-assets/Group 1929.svg";
import ProductWhiteSvg from "../images/raw-assets/Group 1932.svg";
import OrderWhiteSvg from "../images/raw-assets/Group 1931.svg";
import SettingSvg from "../images/raw-assets/Group 2219.svg";
import SettingWhiteSvg from "../images/raw-assets/Group 2220.svg";
import LBILogo from "../images/png/lbi-logo.png";

const SidebarComponent = () => {
    const location = useLocation();
    const pathRaw = location.pathname.split('/')
    const [sideBarSelectedKey, setSideBarSelectedKey] = useState('order')
    const [sideBarChildSelectedKey, setSideBarChildSelectedKey] = useState(null)
    const sidebarList = [
        {
            menuLabel: 'orders',
            icon: OrderSvg,
            iconWhite: OrderWhiteSvg,
            child: [
                {
                    label: 'Order List',
                    key: 'order-list',
                }
            ]
        }, {
            menuLabel: 'products',
            icon: ProductSvg,
            iconWhite: ProductWhiteSvg,
            child: [
                {
                    label: 'Product List',
                    key: 'product-list',
                    path: '/product-list'
                }
            ]
        }, {
            menuLabel: 'balance',
            icon: BalanceSvg,
            iconWhite: BalanceWhiteSvg,
        }, {
            menuLabel: 'settings',
            icon: SettingSvg,
            iconWhite: SettingWhiteSvg,
        },
    ]

    useEffect(() => {
        setSideBarSelectedKey(pathRaw[1])
        setSideBarChildSelectedKey(pathRaw[2])
    }, [])

    const onClickMenu = (key) => {
        setSideBarSelectedKey(key)
    }

    const onClickMenuChild = (key) => {
        setSideBarChildSelectedKey(key)
    }

    return (
        <div className={"mt-20 col items-center w-[270px] fixed text-tiny space-y-7 pb-5"}>
            <div className={"h-[550px] space-y-7"}>
                {
                    sidebarList.map((item, index) => (
                        <div className={`w-40 ${sideBarSelectedKey === item.menuLabel && 'sidebar-selected-area'}`}
                             key={index}>
                            {
                                item?.child?.length > 0 ?
                                    <button
                                        className={`row items-center w-full h-10 px-2 space-x-3 ${sideBarSelectedKey === item.menuLabel ? 'sidebar-selected-button' : 'text-dark-grey-blue'}`}
                                        onClick={() => onClickMenu(item.menuLabel)}
                                    >
                                        <img src={sideBarSelectedKey === item.menuLabel ? item.iconWhite : item.icon}
                                             className={"w-7"}/>
                                        <p className={"capitalize font-Poppins-Bold"}>{item.menuLabel}</p>
                                    </button> :
                                    <Link
                                        className={`row items-center w-full h-10 px-2 space-x-3 ${sideBarSelectedKey === item?.menuLabel ? 'sidebar-selected-button' : 'text-dark-grey-blue'}`}
                                        to={`${item.menuLabel}`} onClick={() => onClickMenu(item?.menuLabel)}
                                    >
                                        <img src={sideBarSelectedKey === item.menuLabel ? item.iconWhite : item.icon}
                                             className={"w-7"}/>
                                        <p className={"capitalize font-Poppins-Bold"}>{item.menuLabel}</p>
                                    </Link>
                            }
                            {
                                (sideBarSelectedKey === item.menuLabel && item?.child?.length > 0) &&
                                item.child.map((i, idx) => (
                                    <Link to={`${item.menuLabel}/${i.key}`} className={"h-10 px-2"}
                                          onClick={() => onClickMenuChild(i.key)} key={idx}>
                                        <div
                                            className={`${sideBarChildSelectedKey === i.key && 'border-l-2 border-l-water-blue'} h-6 text-water-blue row items-center justify-center font-semibold`}>
                                            {i.label}
                                        </div>
                                    </Link>
                                ))
                            }
                        </div>
                    ))
                }
            </div>
            <img src={LBILogo} className={"mt-10"}/>
        </div>
    )
}

export default SidebarComponent
