import { createSlice } from '@reduxjs/toolkit'

export const storeSlice = createSlice({
    name: 'store',
    initialState: {
        storeList: [],
        storeDetail: {},
        reload: false,
        storeId: null
    },
    reducers: {
        setStoreList: (state, action) => {
            state.storeList = action.payload
        },
        setStoreDetail: (state, action) => {
            state.storeDetail = action.payload
        },
        setStoreReload: (state, action) => {
            state.reload = action.payload
        },
        setStoreId: (state, action) => {
            state.storeId = action.payload
        },
    },
})

export const { setStoreList, setStoreReload, setStoreDetail, setStoreId } = storeSlice.actions

const storeReducer = storeSlice.reducer

export default storeReducer
