import React, {useEffect} from "react";
import {Table} from "antd";
import {getBankListApi} from "../../api/bank.api";
import {useDispatch, useSelector} from "react-redux";
import {setUserBankList} from "../../reducers/user.reducer";
import {bankLogo} from "../../ui-utils/general-variable";
import TotalDataComponent from "../../components/total-data.component";

const BankComponent = () => {
    const dispatch = useDispatch()
    const {userBankList, reload} = useSelector(state => state.userReducer)
    const columns = [
        {
            title: 'Bank',
            key: 'bankName',
            dataIndex: 'bank_name',
            className: "text-sm text-dark-blue-grey",
            render: (bank_name, record) =>
                <div className={"row items-center space-x-5"}>
                    <img src={record?.bank_code && bankLogo[record?.bank_code]} className={"w-14"}/>
                    <p className={"capitalize font-bold"}>{bank_name}</p>
                </div>
        }, {
            title: 'No. Rekening',
            key: 'accountNumber',
            dataIndex: 'value',
            className: "text-sm text-dark-grey-blue",
            render: (value) => <p>{value}</p>
        }, {
            title: 'Atas Nama',
            key: 'accountName',
            dataIndex: 'name',
            className: "text-sm text-dark-grey-blue",
            render: (name) => <p className={"capitalize"}>{name}</p>
        }
    ]

    useEffect(() => {
        getBankListApi()
            .then(result => {
                dispatch(setUserBankList(result))
            })
            .catch(err => {
                console.log('Error getBankListApi', err)
            })
    }, [reload])

    return (
        <div className={"font-Poppins-Regular"}>
            <div className={"row items-center space-x-10 mb-5"}>
                <p className={"text-2xl capitalize text-dark-blue-grey font-Poppins-Bold"}>Bank Account</p>
                <TotalDataComponent slug={'akun bank'}/>
            </div>
            <Table
                rowKey={'id'}
                dataSource={userBankList}
                columns={columns}/>
        </div>
    )
}

export default BankComponent
