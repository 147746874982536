import React from "react";
import {Modal} from "antd";

export const ConfirmModal = (props) => {
  const {title, content, onOkHandler} = props
      Modal.confirm({
        title,
        content,
        okText: 'Yes',
        cancelText: 'Cancel',
        onOk: onOkHandler
      });
}
