import { createSlice } from '@reduxjs/toolkit'

export const bankSlice = createSlice({
    name: 'bank',
    initialState: {
        bankList: [],
        selectedBank: {},
        reload: false,
        validBankAccount: {}
    },
    reducers: {
        setBankList: (state, action) => {
            state.bankList = action.payload
        },
        setSelectedBank: (state, action) => {
            state.selectedBank = action.payload
        },
        setValidBankAccount: (state, action) => {
            state.validBankAccount = action.payload
        },
        setBankReload: (state, action) => {
            state.reload = action.payload
        },
    },
})

export const { setBankList, setSelectedBank, setValidBankAccount, setBankReload } = bankSlice.actions

const bankReducer = bankSlice.reducer

export default bankReducer
