import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import {getProductDetailApi} from "../../api/product.api";
import {useDispatch, useSelector} from "react-redux";
import {setProductDetail} from "../../reducers/product.reducer";
import DetailComponent from "./detail.component";
import VariantComponent from "./variant.component";
import {getStoreListApi} from "../../api/store.api";
import {setStoreId, setStoreList} from "../../reducers/store.reducer";

export const ProductDetailComponent = () => {
    const dispatch = useDispatch()
    const {id} = useParams();
    const {productDetail, reload} = useSelector(state => state.productReducer)
    const {storeId} = useSelector(state => state.storeReducer)

    useEffect(() => {
        getStoreListApi()
            .then(result => {
                dispatch(setStoreList(result))
                dispatch(setStoreId(result[0].id))
            })
            .catch(err => {
                console.log('Error getStoreListApi', err)
            })
    }, [])

    useEffect(() => {
        getProductDetail()
    }, [id, storeId, reload])

    const getProductDetail = () => {
        getProductDetailApi(id, {storeId, language: 'id'})
            .then(result => {
                dispatch(setProductDetail(result))
            })
            .catch(err => {
                console.log('Error getProductDetailApi', err)
            })
    }

    if (productDetail?.id) {
        return (
            <div className={"card-container"}>
                <DetailComponent/>
                <VariantComponent/>
            </div>
        )
    }
}
