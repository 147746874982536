import React from "react";
import ExportSvg from "../images/raw-assets/Group 1942.svg";
import {getOrderListExportApi} from "../api/order.api";
import {useSelector} from "react-redux";

const ExportOrderComponent = () => {
    const {selectedRowKeys} = useSelector(state => state.orderReducer)

    const getOrderFileExport = () => {
        getOrderListExportApi({ids: selectedRowKeys})
            .then(response => window.open(response, '_.blank').focus())
            .catch(err => console.log('getOrderListExportApi', err))
    }
    return (
        <button className={'button-rounded-shadow'} onClick={() => getOrderFileExport()}>
            <img src={ExportSvg} className={"w-5"}/>
            Export
        </button>
    )
}

export default ExportOrderComponent
