import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {CloseOutlined} from "@ant-design/icons"
import {setToolsPrintLabelModalVisibility} from "../reducers/tools.reducer";
import Barcode from "react-barcode/lib/react-barcode";
import LbiLogo from "../images/png/Group 1773.png";
import html2canvas from "html2canvas"
import {getCourierImage} from "../ui-utils/general-variable";
import PrintSvg from "../images/raw-assets/Group 1951-white.svg";
import {PACKING} from "../models/order.model";
import {updateOrderStateApi} from "../api/order.api";
import {SuccessModal} from "./success.modal";
import {setOrderReload} from "../reducers/order.reducer";
import jsPDF from "jspdf";

const PrintLabelComponent = ({slug}) => {
    const dispatch = useDispatch()
    const {printLabelModalVisibility} = useSelector(state => state.toolsReducer)
    const {reload, orderListToPrint} = useSelector(state => state.orderReducer)

    const closeModal = () => {
        dispatch(setToolsPrintLabelModalVisibility('invisible'))
    }

    const downloadLabel = () => {
        const input = document.getElementById('divToPrint');
        window.scrollTo(0, 0);
        const resolution = 5
        html2canvas(input, {
            allowTaint: true,
            useCORS: true,
            windowHeight: window.innerHeight,
            windowWidth: window.innerWidth,
            scale: resolution
        })
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF({
                    orientation: "portrait",
                    unit: "px",
                    format: [canvas.width, (560 * resolution)],
                    hotfixes: ["px_scaling"]
                });
                pdf.addImage(imgData, 'JPEG', 0, 0);
                let totalPDFPages = Math.ceil(canvas.height / (560 * resolution))
                for (let i = 1; i <= (totalPDFPages - 1); i++) {
                    pdf.addPage();
                    pdf.addImage(imgData, 'JPG', 0, -(i * (560 * resolution)))
                }
                pdf.save("Print Label.pdf");
                if (!slug) {
                    changeOrderStatus()
                } else {
                    closeModal()
                }
            })
    }

    const changeOrderStatus = () => {
        const orderId = []
        orderListToPrint.forEach(item => {
            orderId.push(item.id)
        })

        let requestBody = {
            ids: orderId,
            status: PACKING
        }
        updateOrderStateApi(requestBody)
            .then(response => {
                dispatch(setOrderReload(!reload))
                closeModal()
                SuccessModal('Print label berhasil di download')
            })
            .catch(err => {
                console.log(err)
            })
    }

    const buildFlexibleHeight = (variant) => {
        if (variant.length > 22) {
            return 'min-h-[1661px]'
        } else if (variant.length > 7) {
            return 'min-h-[1102px]'
        } else {
            return 'min-h-[543px]'
        }
    }

    return (
        <div
            className={`${printLabelModalVisibility} absolute fixed flex left-0 top-0 w-full h-full justify-center items-center bg-gray-400 bg-opacity-30 z-20`}>
            <div
                className="col items-center w-2/6 bg-white max-h-fit p-5 rounded-lg shadow-2xl font-Poppins-Bold font-bold space-y-5">
                <div className={"row self-end"}>
                    <CloseOutlined onClick={closeModal}/>
                </div>
                <div className={"flex justify-center w-25 max-h-40 overflow-y-scroll "}>
                    <div className={"col px-3 py-2 text-black text-sm h-full space-y-4"} id={'divToPrint'}>
                        {
                            orderListToPrint.map((item, index) => (
                                <div key={index}
                                     className={`border-2 border-black ${buildFlexibleHeight(item.variants)} `}>
                                    <div className={"row justify-between w-full items-center px-5"}>
                                        <img src={LbiLogo} className={"h-8"}/>
                                        {item?.awb &&
                                            <Barcode value={item?.awb}
                                                     textPosition={"bottom"}
                                                     textMargin={5}
                                                     height={50}
                                                     width={1.3}
                                                     fontSize={13}/>
                                        }
                                        <div>
                                            <img src={getCourierImage(item?.shipping_method)}
                                                 className={"w-20 h-auto"}/>
                                        </div>
                                    </div>
                                    <div className={"border-y-2 border-black p-2"}>
                                        <div className={"row space-x-1 w-full"}>
                                            <p>Penerima :</p>
                                            <p className={"w-3/5 capitalize"}>{item?.receiver} ({item?.receiver_phone})</p>
                                        </div>
                                        <p>{item?.shipping_address}</p>
                                    </div>
                                    <div className={"p-2"}>
                                        <div className={"row space-x-2 w-full"}>
                                            <p>Pengirim :</p>
                                            <p className={"w-3/5 capitalize"}>{item?.store?.name}</p>
                                        </div>
                                        <p>{item?.seller_address}</p>
                                    </div>
                                    <div className={"grid grid-cols-2 border-y-2 border-black"}>
                                        <div className={"row space-x-1 text-xs p-2 border-r-2 border-black"}>
                                            No. Order : {item?.id}
                                        </div>
                                        <div className={"row p-2 space-x-1 text-xs"}>
                                            Berat : {item?.total_weight} gr
                                        </div>
                                    </div>
                                    <div>
                                        <div className={"grid grid-cols-12 gap-1 mb-2"}>
                                            <div className={"col-span-1 text-center"}>
                                                No.
                                            </div>
                                            <div className={"col-span-7"}>
                                                Nama Produk
                                            </div>
                                            <div className={"col-span-3"}>
                                                Variant
                                            </div>
                                            <div className={"col-span-1 text-center"}>
                                                QTY
                                            </div>
                                        </div>
                                        <div className={"col"}>
                                            {
                                                item?.variants.map((i, idx) => (
                                                    <div className={"grid grid-cols-12 gap-1 h-9"}
                                                         key={idx + index}>
                                                        <div className={"col-span-1 text-center"}>{idx + 1}</div>
                                                        <div
                                                            className={"col-span-7 capitalize"}>{i?.product?.name}</div>
                                                        <div className={"col-span-3 capitalize"}>{i?.name}</div>
                                                        <div
                                                            className={"col-span-1 text-center"}>{i?.order_variant?.quantity}</div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className={"row items-center justify-center space-x-5 text-sm mt-5"}>
                    <button
                        className={"text-faded-blue w-32 border h-8 border-light-grey-blue bg-ice-blue-four rounded-full"}
                        onClick={closeModal}
                    >Tutup
                    </button>
                    <button
                        className={"button-gradient w-32 h-8 rounded-full row items-center pr-2 justify-center"}
                        onClick={() => downloadLabel()}>
                        <img src={PrintSvg} className={"w-7"}/>
                        <p>Print Label</p>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default PrintLabelComponent
