import md5 from 'react-native-md5';
import moment from "moment";
import JneIcon from "../images/shipping-logo/jne.png";
import JntIcon from "../images/shipping-logo/jnt2.png";
import {APPROVED, CANCELLED, COMPLETED, DELIVERY, ON_HOLD, PACKING, PROCESSING, SUBMITTED} from "../models/order.model";
import BRISvg from "../images/png/bri.png";
import BNISvg from "../images/png/bni.png";
import BCASvg from "../images/png/bca.png";
import {DRAFT, PUBLISHED} from "../models/product.model";
import MandiriSvg from "../images/png/mandiri.png";
import PermataSvg from "../images/png/permata.png";

const authToken = localStorage.getItem('LBI_SELLER_TOKEN');
const idLocale = require('moment/locale/id');

const generateMd5Id = (randomString) => {
    return md5.hex_md5(randomNumber(6) + randomString);
};

const randomNumber = (n) => {
    let add = 1,
        max = 12 - add; // 12 is the min safe number Math.random() can generate without it starting to pad the end with zeros.

    if (n > max) {
        return randomNumber(max) + randomNumber(n - max);
    }

    max = Math.pow(10, n + add);
    let min = max / 10; // Math.pow(10, n) basically
    let number = Math.floor(Math.random() * (max - min + 1)) + min;

    return ('' + number).substring(add);
};

const doConvertNumberToRupiahFormat = (numberToFormat) => {
    return new Intl.NumberFormat('id-ID', {style: 'currency', currency: 'IDR'}).format(numberToFormat);
};

const statusText = (status) => {
    switch (status) {
        case SUBMITTED:
            return 'Submitted'
        case ON_HOLD:
            return 'On Hold'
        case PROCESSING:
            return 'Processing'
        case APPROVED:
            return 'Approved'
        case PACKING:
            return 'Packing'
        case DELIVERY:
            return 'Delivery'
        case COMPLETED:
            return 'Completed'
        case CANCELLED:
            return 'Cancelled'
        case 'HIDDEN':
            return 'Hidden'
        case PUBLISHED:
            return 'Published'
        case DRAFT:
            return 'Draft'
        case 'ALL':
        case 'all':
            return 'All'
        default:
            return ''
    }
}

const convertDateToCustomFormat = (date, format) => {
    moment.locale('id', idLocale);
    return moment(date).format(format)
};

const getCourierImage = (courier) => {
    if(courier.toLowerCase().includes('jne')){
        return JneIcon
    } else {
        return JntIcon
    }
}

const bankLogo = {
    bri: BRISvg,
    bni: BNISvg,
    bca: BCASvg,
    mandiri: MandiriSvg,
    permata: PermataSvg
}

const defineTextColor = (text) => {
  switch (text) {
      case 'approve':
          return 'text-dark-seafoam'
      case 'reject':
          return 'text-pastel-red'
      case 'pending':
          return 'text-light-orange'
  }
}

export {
    authToken,
    generateMd5Id,
    doConvertNumberToRupiahFormat,
    statusText,
    convertDateToCustomFormat,
    getCourierImage,
    bankLogo,
    defineTextColor
}
