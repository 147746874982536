import OrderIcon from "../../images/raw-assets/Group 1944.svg";
import {doConvertNumberToRupiahFormat} from "../../ui-utils/general-variable";
import {useSelector} from "react-redux";

export default function OrderProductDetailComponent() {
    const {orderDetail} = useSelector(state => state.orderReducer)

    return (
        <>
            <div>
                <div className={'row items-center space-x-2'}>
                    <img className="w-6" src={OrderIcon} alt={'btn'}/>
                    <p className={"font-bold"}>Pesanan</p>
                </div>
                <div className={"mt-5 ml-8 col space-y-5"}>
                    {
                        !!orderDetail?.variants &&
                        orderDetail?.variants.map((item, index) => (
                            <div className={"min-h-20 grid grid-cols-12 gap-5"} key={index}>
                                <img className="w-12 object-contain col-span-1"
                                     src={item.product.product_images[0].image_url}
                                     alt={''}/>
                                <div className={"col col-span-4 capitalize"}>
                                    <p className={"font-Poppins-Bold"}>{item?.product?.name}</p>
                                    <p className={"italic text-gray-500 "}>{item?.name}</p>
                                </div>
                                <div className={"row col-span-4 justify-around"}>
                                    <p className={"font-Poppins-Bold"}>{item.order_variant.quantity}x</p>
                                    <p className={"col-span-2"}>{doConvertNumberToRupiahFormat(item.order_variant.history_price)}</p>
                                </div>
                                <p className={"col-span-3 justify-end row"}>{doConvertNumberToRupiahFormat(item.order_variant.quantity * item.order_variant.history_price)}</p>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className={'border-t'}>
                <div className={"mt-5 ml-8 col space-y-2"}>
                    <div className={"row justify-between font-Poppins-Bold text-dark-grey-blue"}>
                        <p>Sub Total</p>
                        <p>{doConvertNumberToRupiahFormat(orderDetail?.total_price)}</p>
                    </div>
                    <div className={"row justify-between"}>
                        <p className={"mb-1"}>Diskon</p>
                        <p className={"text-gray-500 "}>- {doConvertNumberToRupiahFormat(orderDetail?.total_discount)}</p>
                    </div>
                    <div className={"row justify-between "}>
                        <p className={"mb-1"}>Biaya Pengiriman</p>
                        <p className={"text-gray-500 "}>{doConvertNumberToRupiahFormat(orderDetail?.shipping_price)}</p>
                    </div>
                </div>
                <div
                    className={"bg-light-blue-grey h-10 rounded-lg -mr-4 ml-4 mt-5 font-Poppins-Bold text-dark-grey-blue col justify-center"}>
                    <div className={"row justify-between py-2 pl-4 pr-2"}>
                        <p>Total</p>
                        <p>{doConvertNumberToRupiahFormat(orderDetail?.grand_total)}</p>
                    </div>
                </div>
            </div>
        </>
    )
}
