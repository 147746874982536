import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {CloseOutlined} from "@ant-design/icons";
import {setToolsEditProfileModalVisibility} from "../../reducers/tools.reducer";
import {Form, Input, Select} from "antd";
import FormItemComponent from "../../components/form-item.component";
import TextArea from "antd/es/input/TextArea";
import {getDistrictListApi, getPostalCodeApi} from "../../api/biteship.api";
import {cloneDeep} from "lodash";
import {getUserDetailApi, updateSellerProfileApi} from "../../api/auth.api";
import {setUserDetail} from "../../reducers/admin.reducer";
import {SuccessModal} from "../../components/success.modal";

const EditProfileModal = () => {
    const ref = useRef();
    const dispatch = useDispatch()
    const [selectedAddress, setSelectedAddress] = useState({})
    const [districtList, setDistrictList] = useState([])
    const {adminDetail} = useSelector(state => state.userAdminReducer)
    const {editProfileModalVisibility} = useSelector(state => state.toolsReducer)
    const listForm = [
        {
            type: 'input',
            label: 'Email',
            name: 'email',
            placeholder: 'Masukkan email'
        }, {
            type: 'input',
            label: 'Nomor Telpon',
            name: 'phone',
            placeholder: 'Masukkan nomor telpon'
        }, {
            type: 'search',
            label: 'Provinsi, Kota, Kecamatan, Kode Pos',
            name: 'addressFull',
            placeholder: 'Masukkan provinsi, kota, kecamatan dan kode pos'
        }, {
            type: 'textarea',
            label: 'Alamat',
            name: 'address',
            placeholder: 'Masukkan alamat'
        },
    ]

    useEffect(() => {
        const admin = cloneDeep(adminDetail)
        const getAddress = async () => {
            try {
                const adminData = await getDistrictListApi(adminDetail?.district)
                admin.addressFull = adminData.areas[0].name
                ref.current.setFieldsValue(admin)
            } catch (e) {
                console.log('gagal', e)
            }
        }
        getAddress()
    }, [adminDetail])

    const closeModal = () => {
        dispatch(setToolsEditProfileModalVisibility('hidden'))
    }

    const searchDistrict = (address) => {
        getDistrictListApi(address)
            .then(result => {
                setDistrictList(result.areas)
            })
            .catch(err => console.log('Error searchDistrict', err))
    }

    const getValueFromSelect = (value) => {
        getPostalCodeApi(value)
            .then(result => {
                setSelectedAddress(result.areas[0])
            })
            .catch(err => console.log("error get address list :", err))
    }

    const onFinish = (values) => {
        values.district = selectedAddress?.administrative_division_level_3_name
        values.city = selectedAddress?.administrative_division_level_2_name
        values.province = selectedAddress?.administrative_division_level_1_name
        values.postal_code = selectedAddress?.postal_code
        updateSellerProfile(values)
    }

    const updateSellerProfile = (data) => {
        updateSellerProfileApi(data)
            .then(result => {
                getUserDetail()
            })
            .catch(err => console.log(err))
    }

    const getUserDetail = () => {
        getUserDetailApi()
            .then(result => {
                dispatch(setUserDetail({...result.data}))
                closeModal()
                SuccessModal('Profile detail berhasil di update!')
            })
            .catch(err => {
                alert("error get detail profile !!")
                console.log("err get detail admin :", err)
            })
    }

    return (
        <div
            className={`${editProfileModalVisibility} absolute fixed flex left-0 top-0 w-full h-full justify-center items-center bg-gray-400 bg-opacity-30 z-20`}>
            <div className="col w-2/6 bg-white p-5 z-10 rounded-lg shadow-2xl font-Poppins-Bold space-y-5">
                <div className={"flex justify-end"}>
                    <CloseOutlined onClick={closeModal}/>
                </div>
                <p className={"font-Poppins-Bold text-center"}>Edit Profile</p>
                <div className={"flex flex-row justify-center"}>
                    <Form
                        ref={ref}
                        layout="vertical"
                        className={"text-sm"}
                        onFinish={onFinish}
                    >
                        {
                            listForm.map((i, idx) => {
                                switch (i.type) {
                                    case 'search':
                                        return (
                                            <FormItemComponent
                                                key={idx}
                                                item={i}
                                                content={
                                                    <Select
                                                        showSearch
                                                        placeholder={i.placeholder}
                                                        defaultActiveFirstOption={false}
                                                        filterOption={false}
                                                        style={{
                                                            width: 400,
                                                            borderRadius: 12
                                                        }}
                                                        onSearch={searchDistrict}
                                                        onChange={getValueFromSelect}
                                                        notFoundContent={null}
                                                    >
                                                        {
                                                            districtList?.length > 0 &&
                                                            districtList.map(d => <Select.Option
                                                                key={d.id}>{d?.name}</Select.Option>)
                                                        }
                                                    </Select>
                                                }
                                            />
                                        )
                                    case 'input':
                                        return (
                                            <FormItemComponent
                                                key={idx}
                                                item={i}
                                                content={
                                                    <Input
                                                        style={{
                                                            width: 400,
                                                            height: 40,
                                                            borderRadius: 12
                                                        }} placeholder={i.placeholder}/>
                                                }
                                            />
                                        )
                                    default:
                                        return (
                                            <FormItemComponent
                                                key={idx}
                                                item={i}
                                                content={
                                                    <TextArea style={{
                                                        width: 400,
                                                        borderRadius: 12
                                                    }} rows={4}/>
                                                }
                                            />
                                        )
                                }
                            })
                        }
                        <Form.Item>
                            <div className={"flex justify-center"}>
                                <button className={"button-gradient px-5 py-2 rounded-full"}>
                                    Update Profile
                                </button>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default EditProfileModal
