import React, {useEffect, useState} from "react";
import {CloseOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import {setToolsBankModalVisibility} from "../../../reducers/tools.reducer";
import {Steps} from "antd";
import ListBankComponent from "./list-bank.component";
import CheckBankComponent from "./check-bank.component";
import SaveBankComponent from "./save-bank.component";
import {getListOfBankApi} from "../../../api/iris.api";
import {setBankList} from "../../../reducers/bank.reducer";
import {createBankApi} from "../../../api/bank.api";
import {SuccessModal} from "../../../components/success.modal";
import {setUserReload} from "../../../reducers/user.reducer";

const {Step} = Steps;

const BankModal = () => {
    const dispatch = useDispatch()
    const {bankModalVisibility} = useSelector(state => state.toolsReducer)
    const {selectedBank, validBankAccount} = useSelector(state => state.bankReducer)
    const {reload} = useSelector(state => state.userReducer)
    const [currentStep, setCurrentStep] = useState(0)
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {
        getListOfBankApi()
            .then(result => {
                dispatch(setBankList(result))
            })
            .catch(err => {
                console.log('Error getListOfBankApi', err)
            })
    }, [reload])

    const closeModal = () => {
        dispatch(setToolsBankModalVisibility('hidden'))
    }

    const nextStepHandler = () => {
        if (currentStep === 0 && !selectedBank?.code) {
            setErrorMessage('Anda harus memilih salah satu bank')
        } else if (currentStep === 1 && !validBankAccount?.account_name) {
            setErrorMessage('Akun bank belum ditemukan')
        } else if (currentStep === 2) {
            saveBankAccount()
        } else {
            setCurrentStep(currentStep + 1)
        }
    }

    const saveBankAccount = () => {
        let requestBody = {
            name: validBankAccount?.account_name,
            bank_name: selectedBank?.name,
            bank_code: validBankAccount?.bank_name,
            value: validBankAccount?.account_no
        }
        createBankApi(requestBody)
            .then(response => {
                dispatch(setUserReload(!reload))
                closeModal()
                SuccessModal('Bank berhasil disimpan')
            })
            .catch(err => {
                console.log('Error createBankApi', err)
            })
    }

    const stepContent = [<ListBankComponent/>, <CheckBankComponent/>, <SaveBankComponent/>]

    return (
        <div
            className={`${bankModalVisibility} absolute fixed flex left-0 top-0 w-full h-full justify-center items-center bg-gray-400 bg-opacity-30 z-20`}>
            <div className="col w-2/7 max-h-50 min-h-30 bg-white p-5 z-10 rounded-lg shadow-2xl font-Poppins-Regular">
                <div className={"flex justify-end"}>
                    <CloseOutlined onClick={closeModal}/>
                </div>
                <p className={"font-Poppins-Bold text-center my-5"}>Tambah Akun Bank</p>
                <div className={"flex-1 h-full col items-center justify-between text-sm"}>
                    <div className={"col items-center h-full"}>
                        <Steps current={currentStep} progressDot>
                            <Step title={<p className={"text-sm font-Poppins-Regular"}>Pilih Bank</p>}/>
                            <Step title={<p className={"text-sm font-Poppins-Regular"}>Nomor Rekening</p>}/>
                            <Step title={<p className={"text-sm font-Poppins-Regular"}>Konfirmasi</p>}/>
                        </Steps>
                        {stepContent[currentStep]}
                    </div>
                    <div>
                        {
                            currentStep < 3 &&
                            <div className={"row justify-center"}>
                                <button className={"button-gradient px-5 py-1 rounded-xl font-bold"}
                                        onClick={nextStepHandler}>{currentStep === 2 ? 'Simpan' : 'Lanjut'}
                                </button>
                            </div>
                        }
                        {!!errorMessage && <p className={"text-xs text-dusty-red text-center mt-2"}>{errorMessage}</p>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BankModal
