import {Timeline} from "antd";
import {useSelector} from "react-redux";
import {convertDateToCustomFormat} from "../../ui-utils/general-variable";

export default function TrackingOrderDetailComponent(){
    const {trackingCourier} = useSelector(state => state.orderReducer)
    return(
        <>
            <div className={'col justify-center items-center rounded-xl border container bg-white shadow-xl border border-blue-200'}>
                <h2 className={'pt-4 font-bold'}>Tracking Courier</h2>
                <div className={'pt-4 border-t w-full'}>
                    <div className={'px-8 py-2 text-xs'}>
                        <Timeline>
                            {
                                trackingCourier.map((item, index) => (
                                    <Timeline.Item key={index} className={"min-h-20"}>
                                        <p className={'capitalize'}>{item.note}</p>
                                        <p className={'text-sm'}>{convertDateToCustomFormat(item.updated_at, 'lll')}</p>
                                    </Timeline.Item>
                                ))
                            }
                        </Timeline>
                    </div>
                </div>
            </div>
        </>
    )
}
