import axios from "axios";

export const BITESHIP_KEY = 'biteship_test.eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiQVBJIEJpdGVzaGlwIFN0YWdpbmciLCJ1c2VySWQiOiI2MjdjNmIwNjJjNTk2YzZiNjU0Y2I4OTIiLCJpYXQiOjE2NTQyMzk0ODZ9.pYru_rXGqUJvrf7UO9K8i71lvQZFyNVYsKprtm9G2-0'

const getDistrictListApi = (query) => {
    return new Promise((resolve, reject) => {
        axios.get(
            `https://api.biteship.com/v1/maps/areas?countries=ID&input=${query}&type=double`,
            {headers: {'Authorization': BITESHIP_KEY}})
            .then(response => resolve(response.data))
            .catch(err => reject(err))
    })
}

const getPostalCodeApi = (areaId) => {
    return new Promise((resolve, reject) => {
        axios.get(
            `https://api.biteship.com/v1/maps/areas/${areaId}`,
            {headers: {'Authorization': BITESHIP_KEY}})
            .then(response => resolve(response.data))
            .catch(err => reject(err))
    })
}

export {
    getDistrictListApi,
    getPostalCodeApi
}
