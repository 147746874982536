import React, {useEffect} from "react";
import {authToken} from "../ui-utils/general-variable";
import {useDispatch} from "react-redux";
import {getUserDetailApi} from "../api/auth.api";
import {setUserDetail} from "../reducers/admin.reducer";
import {Colors} from "../ui-utils/colors";
import {Layout} from "antd";
import SidebarComponent from "./sidebar.component";
import HeaderComponent from "./header.component";

const {Content} = Layout;

const MainDashboardComponent = (props) => {
    const {content} = props
    const token = authToken
    const dispatch = useDispatch()

    useEffect(() => {
        if(!!token){
            getAdminDetail()
        }
    }, [])

    const getAdminDetail = () => {
        getUserDetailApi()
            .then(result => {
                dispatch(setUserDetail({...result.data}))
            })
            .catch(err => {
                alert("error get detail profile !!")
                console.log("err get detail admin :", err)
            })
    }

    if (!!token) {
        return (
            <div className={"flex flex-row"}>
                <SidebarComponent/>
                <div className={`flex-1 flex-col ml-[270px] max-h-screen overflow-y-scroll`}>
                    <div className={"w-full flex-1 flex-row items-center sticky top-0 bg-pale-blue h-20 px-10 z-20"}>
                        <HeaderComponent/>
                    </div>
                    <div className={"flex-1 flex-col pr-10 pb-10"}>
                        {content}
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <Layout>
                <Content style={{
                    justifyContent:'center',
                    alignContent:'center',
                    backgroundColor: Colors.pale_blue,
                    width: '100%',
                    height: '100vh'
                }}>{content}</Content>
            </Layout>
        )
    }
}

export default MainDashboardComponent
