import React from "react";
import UserBalanceComponent from "./user-balance.component";
import TabComponent from "./tab.component";
import PayoutListComponent from "./payout-list.component";
import TotalDataComponent from "../../components/total-data.component";
import {useDispatch, useSelector} from "react-redux";
import {setCreatePayoutModalVisibility} from "../../reducers/tools.reducer";
import CreateRequestModal from "./create-request.modal";

export const BalanceComponent = () => {
    const dispatch = useDispatch()
    const {createPayoutModalVisibility} = useSelector(state => state.toolsReducer)

    const createRequestHandler = () => {
        dispatch(setCreatePayoutModalVisibility('block'))
    }

    return (
        <>
            <div className={"card-container space-y-8"}>
                <UserBalanceComponent/>
                <div className={"space-y-4"}>
                    <TabComponent/>
                    <div className={"row items-center justify-between"}>
                        <div className={"row items-center space-x-5"}>
                            <p className={"text-dark-grey-blue text-xl font-Poppins-Bold"}>Payout Request</p>
                            <TotalDataComponent slug={'payout'}/>
                        </div>
                        <button onClick={createRequestHandler}
                                className={"rounded-full gradient-water-blue border text-white px-10 py-1 justify-content-center font-bold"}>Create
                            Request
                        </button>
                    </div>
                </div>
                <PayoutListComponent/>
            </div>
            {createPayoutModalVisibility === 'block' && <CreateRequestModal/>}
        </>
    )
}
