import {Timeline} from "antd";
import {useSelector} from "react-redux";
import {convertDateToCustomFormat} from "../../ui-utils/general-variable";

export default function HistoryOrderDetailComponent(){
    const {orderHistoryList} = useSelector(state => state.orderReducer)

    return(
        <>
            <div className={'col justify-center items-center rounded-xl border container bg-white shadow-xl border border-blue-200'}>
                <h2 className={'py-2 font-Poppins-Bold'}>History Order</h2>
                <div className={'border-t w-full py-7'}>
                    <div className={'px-8 py-2 text-xs'}>
                        <Timeline>
                            {
                                orderHistoryList.map((item, index) => (
                                    <Timeline.Item key={index} className={"min-h-20"}>
                                        <p className={'capitalize'}>{item.title}</p>
                                        <p className={'text-sm'}>{convertDateToCustomFormat(item.createdAt, 'lll')}</p>
                                    </Timeline.Item>
                                ))
                            }
                        </Timeline>
                    </div>
                </div>
            </div>
        </>
    )
}
