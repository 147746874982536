import React from "react";
import DefaultImage from "../../images/default-image/Profile-Default.png"
import UploadSvg from "../../images/raw-assets/Group 1964.svg"
import KeySvg from "../../images/raw-assets/key.svg"
import {useDispatch, useSelector} from "react-redux";
import {uploadImageApi} from "../../api/upload-image.api";
import {setToolsChangePasswordModalVisibility} from "../../reducers/tools.reducer";
import {getUserDetailApi, updateSellerProfileApi} from "../../api/auth.api";
import {setUserDetail} from "../../reducers/admin.reducer";
import {SuccessModal} from "../../components/success.modal";

const ProfileComponent = () => {
    const dispatch = useDispatch()
    const {adminDetail} = useSelector(state => state.userAdminReducer)
    const profileDetailList = [
        {
            key: 'email',
            label: 'Email',
        }, {
            key: 'phone',
            label: 'No. Telpon',
        }, {
            key: 'address',
            label: 'Alamat',
        }, {
            key: 'area',
            label: 'Area',
        }
    ]

    const uploadImage = (e) => {
        let fileImage = e.target.files[0]
        if (fileImage.size > 2000000) {
            alert('Ukuran gambar terlalu besar. Maks 2 MB')
        } else {
            handleUpload(fileImage)
        }
    }

    const handleUpload = (fileImage) => {
        const formData = new FormData();

        formData.append("image", fileImage)
        formData.append("folder", 'seller')
        uploadImageApi(formData)
            .then(result => {
                updateSellerProfile(result.imageUrl)
            })
            .catch(err => console.log(err))
    }

    const updateSellerProfile = (data) => {
        updateSellerProfileApi({image_url: data})
            .then(result => {
                getUserDetail()
            })
            .catch(err => console.log(err))
    }

    const getUserDetail = () => {
      getUserDetailApi()
          .then(result => {
              dispatch(setUserDetail({...result.data}))
              SuccessModal('Profile image berhasil di update!')
          })
          .catch(err => {
              alert("error get detail profile !!")
              console.log("err get detail admin :", err)
          })
    }

    const openModal = () => {
        dispatch(setToolsChangePasswordModalVisibility('block'))
    }

    return (
        <div className={"py-10"}>
            <div className={"font-Poppins-Regular row text-sm space-x-20"}>
                <div className={"col items-center space-y-5"}>
                    <img src={!!adminDetail?.image_url ? adminDetail?.image_url : DefaultImage} className={"w-52 h-52 rounded-full object-contain"}/>
                    <button className={"px-5 button-gradient py-1 rounded-full"}>
                        <label className={"row items-center cursor-pointer"}>
                            <img src={UploadSvg} className={"w-6"}/>
                            <p>Pilih Foto</p>
                            <input type={"file"} className={"hidden"}
                                   onChange={(e) => uploadImage(e)}/>
                        </label>
                    </button>
                </div>
                <div className={"col"}>
                    <div className={"grid grid-cols-2"}>
                        <div className={"col space-y-5 max-w-16"}>
                            {
                                profileDetailList.map((item, index) => (
                                    <div className={"col space-y-2"} key={index}>
                                        <p className={"font-Poppins-Bold"}>{item.label}</p>
                                        <p>{item.key === 'address' ? `${adminDetail?.address}, ${adminDetail?.district}, ${adminDetail?.city}, ${adminDetail?.province}` : item.key === 'area' ? `${adminDetail?.city}, ${adminDetail?.province}` : adminDetail[item.key]}</p>
                                    </div>
                                ))
                            }
                            <button
                                className={"row items-center justify-center py-2 space-x-2 bg-ice-blue-four border border-faded-blue text-faded-blue font-Poppins-Bold rounded-lg"}
                                onClick={openModal}
                            >
                                <img src={KeySvg} className={"w-3"}/>
                                <p>Ubah Password</p>
                            </button>
                        </div>
                        <div className={"col font-Poppins-Bold ml-10"}>
                            <p>Status</p>
                            <p className={"text-dark-seafoam"}>{adminDetail?.status}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfileComponent
