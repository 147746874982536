import {baseUrl, DEFAULT_API_HEADER, token} from "./api.config";
import axios from "axios";

function getProductListApi(params) {
    return new Promise((resolve, reject) => {
        axios.get(
            `${baseUrl()}/api/v1/seller/product/list`,
            {headers: {'Authorization': `Bearer ${token}`}, params})
            .then(response => resolve(response.data.data))
            .catch(err => reject(err))
    })
}

function getProductDetailApi(id, params) {
    return new Promise((resolve, reject) => {
        axios.get(
            `${baseUrl()}/api/v1/seller/product/detail/${id}`,
            {headers: {'Authorization': `Bearer ${token}`}, params})
            .then(response => resolve(response.data.data))
            .catch(err => reject(err))
    })
}

function updateProductApi(requestBody) {
    return new Promise((resolve, reject) => {
        axios.post(
            `${baseUrl()}/api/v1/seller/product/update/stock`,
            requestBody,
            {headers: {'Authorization': `Bearer ${token}`}})
            .then(response => resolve(response.data.data))
            .catch(err => reject(err))
    })
}

function createProduct(bodyRequest) {
    return new Promise((resolve, reject) => {
        axios.post(
            `${baseUrl()}/api/v1/admin/product/create`,
            bodyRequest,
            {
                headers: {
                    ...DEFAULT_API_HEADER,
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(response => {
                resolve(response.data)
            })
            .catch(err => {
                console.log("err createProduct :", err.response)
                reject(err)
            })
    })
}

function deleteProduct(id) {
    return new Promise((resolve, reject) => {
        axios.get(
            `${baseUrl()}/api/v1/admin/product/delete/${id}`,
            {
                headers: {
                    ...DEFAULT_API_HEADER,
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(response => {
                resolve(response.data)
            })
            .catch(err => {
                console.log("err deleteProduct :", err.response)
                reject(err)
            })
    })
}

export {
    getProductListApi,
    createProduct,
    getProductDetailApi,
    deleteProduct,
    updateProductApi
}
