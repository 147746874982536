import {createSlice} from '@reduxjs/toolkit'

export const productSlice = createSlice({
    name: 'product',
    initialState: {
        productList: [],
        productDetail: {},
        statusSelected: null,
        reload: false
    },
    reducers: {
        setProductList: (state, action) => {
            state.productList = action.payload
        },
        setProductDetail: (state, action) => {
            state.productDetail = action.payload
        },
        setStatusSelected: (state, action) => {
            state.statusSelected = action.payload
        },
        setProductReload: (state, action) => {
            state.reload = action.payload
        }
    },
})

export const {
    setProductList,
    setProductDetail,
    setStatusSelected,
    setProductReload
} = productSlice.actions

const productReducer = productSlice.reducer

export default productReducer
