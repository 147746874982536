import axios from "axios";
import {baseUrl, token} from "./api.config";

const getListOfBankApi = () => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${baseUrl()}/api/v1/payment/list/bank`,
            {headers: {'Authorization': `Bearer ${token}`}})
            .then(response => resolve(response.data.data))
            .catch(err => reject(err))
    });
};

const validateBankApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${baseUrl()}/api/v1/payment/validate/bank`,
            requestBody,
            {headers: {'Authorization': `Bearer ${token}`}})
            .then(response => resolve(response.data.data))
            .catch(err => reject(err))
    });
};

export {
    getListOfBankApi,
    validateBankApi
}
