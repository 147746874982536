import React, {useEffect, useRef, useState} from "react";
import {CloseOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import {setToolsCreateStoreModalVisibility} from "../../reducers/tools.reducer";
import {Form, Input, Select} from "antd";
import TextArea from "antd/es/input/TextArea";
import ToggleOn from "../../images/raw-assets/Group 2213.svg";
import ToggleOff from "../../images/raw-assets/Group 2214.svg";
import {getDistrictListApi, getPostalCodeApi} from "../../api/biteship.api";
import {useParams} from "react-router-dom";
import {createStoreApi, updateStoreApi} from "../../api/store.api";
import {setStoreReload} from "../../reducers/store.reducer";
import {cloneDeep} from "lodash";
import FormItemComponent from "../../components/form-item.component";
import {SuccessModal} from "../../components/success.modal";

const CreateStoreModal = () => {
    const formRef = useRef({});
    const dispatch = useDispatch()
    const {id} = useParams();
    const [isActive, setActive] = useState(true)
    const [selectedAddress, setSelectedAddress] = useState({})
    const [districtList, setDistrictList] = useState([])
    const {createStoreModalVisibility} = useSelector(state => state.toolsReducer)
    const {reload, storeDetail} = useSelector(state => state.storeReducer)
    const formList = [
        {
            type: 'input',
            label: 'Nama Toko',
            name: 'name',
            placeholder: 'Masukkan nama toko'
        }, {
            type: 'input',
            label: 'No. Telpon',
            name: 'phone',
            placeholder: 'Masukkan nomor telpon'
        },
        {
            type: 'search',
            label: 'Provinsi, Kota, Kecamatan, Kode Pos',
            name: 'addressFull',
            placeholder: 'Masukkan provinsi, kota, kecamatan dan kode pos'
        }, {
            type: 'textarea',
            label: 'Alamat',
            name: 'address',
            placeholder: 'Masukkan alamat'
        }, {
            type: 'toggle',
            label: 'Status',
            name: 'status'
        },
    ]

    useEffect(() => {
        if (storeDetail?.id) {
            const address = cloneDeep(storeDetail)
            const getAddress = async () => {
                try {
                    const addressData = await getDistrictListApi(storeDetail?.district)
                    setActive(storeDetail?.status === 'ACTIVE' ? true : false)
                    address.addressFull = addressData.areas[0].name
                    formRef.current.setFieldsValue(address)
                } catch (e) {
                    console.log('gagal', e)
                }
            }
            getAddress()
        }
    }, [storeDetail])

    const closeModal = () => {
        dispatch(setToolsCreateStoreModalVisibility('hidden'))
    }

    const onFinish = (values) => {
        values.status = isActive ? 'ACTIVE' : 'INACTIVE'
        values.district = selectedAddress?.administrative_division_level_3_name
        values.city = selectedAddress?.administrative_division_level_2_name
        values.province = selectedAddress?.administrative_division_level_1_name
        values.postal_code = selectedAddress?.postal_code
        values.seller_id = id
        if (storeDetail?.id) {
            updateStore(values)
        } else {
            createStore(values)
        }
    }

    const createStore = (values) => {
        createStoreApi(values)
            .then(response => {
                closeModal()
                dispatch(setStoreReload(!reload))
                SuccessModal('Store berhasil dibuat')
            })
            .catch(err => {
                console.log(err)
            })
    }

    const updateStore = (values) => {
        updateStoreApi(storeDetail?.id, values)
            .then(response => {
                closeModal()
                dispatch(setStoreReload(!reload))
                SuccessModal('Store berhasil diubah')
            })
            .catch(err => {
                console.log(err)
            })
    }

    const searchDistrict = (address) => {
        getDistrictListApi(address)
            .then(result => {
                setDistrictList(result.areas)
            })
            .catch(err => console.log('Error searchDistrict', err))
    }

    const getValueFromSelect = (value) => {
        getPostalCodeApi(value)
            .then(result => {
                setSelectedAddress(result.areas[0])
            })
            .catch(err => console.log("error get address list :", err))
    }

    return (
        <div
            className={`${createStoreModalVisibility} absolute fixed flex left-0 top-0 w-full h-full justify-center items-center bg-gray-400 bg-opacity-30 z-20`}>
            <div className="col w-2/6 max-h-60 bg-white p-5 z-10 rounded-lg shadow-2xl font-Poppins-Bold space-y-5">
                <div className={"flex justify-end"}>
                    <CloseOutlined onClick={closeModal}/>
                </div>
                <p className={"font-Poppins-Bold text-center"}>Tambah Toko</p>
                <div className={"col items-center"}>
                    <Form
                        ref={formRef}
                        layout="vertical"
                        className={"text-sm"}
                        onFinish={onFinish}
                    >
                        {
                            formList.map((item, index) => {
                                    switch (item.type) {
                                        case 'search':
                                            return (
                                                <FormItemComponent
                                                    key={index}
                                                    item={item}
                                                    content={
                                                        <Select
                                                            showSearch
                                                            placeholder={item.placeholder}
                                                            defaultActiveFirstOption={false}
                                                            filterOption={false}
                                                            style={{
                                                                width: 400,
                                                                borderRadius: 12
                                                            }}
                                                            onSearch={searchDistrict}
                                                            onChange={getValueFromSelect}
                                                            notFoundContent={null}
                                                        >
                                                            {
                                                                districtList.length > 0 &&
                                                                districtList.map(d => <Select.Option
                                                                    key={d.id}>{d?.name}</Select.Option>)
                                                            }
                                                        </Select>
                                                    }
                                                />
                                            )
                                        case 'input':
                                            return (
                                                <FormItemComponent
                                                    key={index}
                                                    item={item}
                                                    content={
                                                        <Input
                                                            style={{
                                                                width: 400,
                                                                height: 40,
                                                                borderRadius: 12
                                                            }} placeholder={item.placeholder}/>
                                                    }
                                                />
                                            )
                                        case 'textarea':
                                            return (
                                                <FormItemComponent
                                                    key={index}
                                                    item={item}
                                                    content={
                                                        <TextArea style={{
                                                            width: 400,
                                                            borderRadius: 12
                                                        }} rows={4}/>
                                                    }
                                                />
                                            )
                                        case 'toggle':
                                            return (
                                                <FormItemComponent
                                                    key={index}
                                                    item={item}
                                                    content={
                                                        <div className={"row items-center space-x-5"}>
                                                            <img src={isActive ? ToggleOn : ToggleOff}
                                                                 className={"w-10 cursor-pointer"}
                                                                 onClick={() => setActive(!isActive)}/>
                                                            <p className={isActive ? 'text-dark-seafoam' : 'text-faded-blue'}>{isActive ? 'Active' : 'Inactive'}</p>
                                                        </div>
                                                    }
                                                />
                                            )
                                    }
                                }
                            )
                        }
                        <Form.Item>
                            <div className={"flex justify-center"}>
                                <button className={"button-gradient px-5 py-2 rounded-full"}>
                                    Simpan Toko
                                </button>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default CreateStoreModal
