import React, {useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import UserSvg from "../images/raw-assets/Group 1952.svg";
import {RightOutlined} from "@ant-design/icons";

const HeaderComponent = () => {
    const location = useLocation();
    const [visibility, setVisibility] = useState(false)
    const pathRaw = location.pathname.split('/')
    const {adminDetail} = useSelector(state => state.userAdminReducer)

    const title = {
        '': 'Order',
        'orders': 'Orders',
        'products': 'Products',
        'settings': 'Settings',
        'balance': 'Balance'
    }

    const subTitle = {
        'order-list': 'Order List',
        'product-list': 'Product List',
    }

    const subSubTitle = {
        'order-detail': 'Order Detail',
        'product-detail': 'Product Detail',
    }

    return (
        <div className={"row items-center justify-between h-full"}>
            <div className={"row items-center space-x-5"}>
                <p className={"capitalize text-2xl text-water-blue font-bold"}>{title[pathRaw[1]]}</p>
                {
                    pathRaw.length > 2 &&
                    <>
                        <div>
                            <RightOutlined/>
                        </div>
                        <Link to={`/${pathRaw[1]}/${pathRaw[2]}`}>
                            <p className={"capitalize text-dark-grey-blue font-bold mt-1.5"}>{subTitle[pathRaw[2]]}</p>
                        </Link>
                    </>
                }
                {
                    pathRaw.length > 3 &&
                    <>
                        <div>
                            <RightOutlined/>
                        </div>
                        <p className={"capitalize text-dark-grey-blue font-bold mt-1.5"}>{subSubTitle[pathRaw[3]]}</p>
                    </>
                }
            </div>
            <div className={"relative"}>
                <div
                    className={"row items-center bg-white pr-10 pl-2 space-x-2 rounded-full shadow-xl py-1 cursor-pointer"}
                    onClick={() => setVisibility(!visibility)}
                >
                    <img src={UserSvg} className={"w-7"}/>
                    <div className={"col text-xs"}>
                        <p className={"font-bold text-dark-grey-blue"}>{adminDetail.name}</p>
                        <p>Administrator</p>
                    </div>
                </div>
                <div
                    className={` ${visibility ? 'visible' : 'invisible'} absolute border mt-3 p-2 bg-white z-10 rounded-lg shadow-lg space-y-5 w-40 col items-center`}>
                    <button
                        className={"px-5 row items-center text-sm text-white font-Poppins-Bold py-1 rounded-full shadow-xl bg-gradient-to-b from-sky-blue to-water-blue"}
                        onClick={() => {
                            localStorage.removeItem('LBI_SELLER_TOKEN')
                            window.location.reload()
                        }}
                    >Logout
                    </button>
                </div>
            </div>
        </div>
    )
}

export default HeaderComponent;
