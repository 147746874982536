import axios from "axios";
import {baseUrl, token} from "./api.config";

const getBankListApi = () => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${baseUrl()}/api/v1/seller/profile/list/bank`,
            {headers: {'Authorization': `Bearer ${token}`}})
            .then(response => resolve(response.data.data))
            .catch(err => reject(err))
    })
}

const createBankApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${baseUrl()}/api/v1/seller/profile/create/bank`,
            requestBody,
            {headers: {'Authorization': `Bearer ${token}`}})
            .then(response => resolve(response.data.data))
            .catch(err => reject(err))
    })
}

export {
    getBankListApi,
    createBankApi
}
