import React, {useState} from "react";
import {Form, Input} from "antd";
import LoadingSvg from "../../../images/raw-assets/Ellipse 478.svg"
import ErrorSvg from "../../../images/raw-assets/Group 2221.svg"
import SuccessSvg from "../../../images/raw-assets/Group 2222.svg"
import FormItemComponent from "../../../components/form-item.component";
import {validateBankApi} from "../../../api/iris.api";
import {useDispatch, useSelector} from "react-redux";
import {setValidBankAccount} from "../../../reducers/bank.reducer";

const CheckBankComponent = () => {
    const dispatch = useDispatch()
    const {selectedBank, validBankAccount} = useSelector(state => state.bankReducer)
    const [status, setStatus] = useState(null)

    const loadingContent = () => {
        return (
            <>
                <img src={LoadingSvg} className={"w-10 animate-spin"}/>
                <p>Sedang Memeriksa..</p>
            </>
        )
    }

    const errorContent = () => {
        return (
            <>
                <img src={ErrorSvg} className={"w-10 animate-bounce"}/>
                <p className={"font-semibold text-dark-grey-blue"}>Tidak Ditemukan</p>
                <p className={"mt-5 w-1/2 text-center text-slate-two"}>Masukkan dan Periksa Kembali Nomor Rekening
                    Anda</p>
            </>
        )
    }

    const successContent = () => {
      return (
          <>
              <img src={SuccessSvg} className={"w-10 animate-bounce"}/>
              <p className={"font-semibold text-dark-grey-blue"}>Ditemukan</p>
              <div className={"mt-3 col items-center justify-center border-lavender border bg-ice-blue w-full py-2 rounded-lg"}>
                  <p className={"text-faded-blue font-semibold"}>Atas Nama</p>
                  <p className={"text-dark-grey-blue"}>{validBankAccount?.account_name}</p>
              </div>
          </>
      )
    }

    const onFinish = (values) => {
        setStatus('loading')
        let requestBody = {
            bank_code: selectedBank?.code,
            value: values?.accountNumber
        }
        validateBankApi(requestBody)
            .then(result => {
                setStatus('success')
                dispatch(setValidBankAccount(result))
            })
            .catch(err => {
                setStatus('error')
                console.log('Error validateBankApi', err)
            })
    }

    const defineContent = {
        loading: loadingContent(),
        error: errorContent(),
        success: successContent()
    }

    return (
        <div className={"mt-5"}>
            <Form
                layout="vertical"
                className={"text-sm"}
                onFinish={onFinish}
            >
                <FormItemComponent
                    item={{name: 'accountNumber', label: 'Nomor Rekening'}}
                    content={
                        <Input
                            style={{
                                width: 400,
                                borderRadius: 5
                            }} placeholder={'Masukkan nomor rekening anda'}/>
                    }
                />
                <button
                    className={"bg-ice-blue-four border border-faded-blue px-7 rounded-md text-faded-blue text-sm font-semibold py-1"}>
                    Periksa Rekening
                </button>
            </Form>
            <div className={"flex flex-col items-center justify-center w-full h-full py-10 text-sm space-y-4"}>
                {defineContent[status]}
            </div>
        </div>
    )
}

export default CheckBankComponent
