import React from "react";
import {Table} from "antd";
import {useDispatch, useSelector} from "react-redux";
import EditSvg from "../../images/raw-assets/Group 2130.svg"
import {Link} from "react-router-dom";
import {setToolsPage} from "../../reducers/tools.reducer";
import TotalDataComponent from "../../components/total-data.component";

const ProductListComponent = () => {
    const dispatch = useDispatch()
    const {totalData, limit, page} = useSelector(state => state.toolsReducer)
    const {productList} = useSelector(state => state.productReducer)

    const columns = [
        {
            title: 'Nama Produk',
            dataIndex: 'name',
            className: 'text-sm',
            key: 'name',
            render: (name, record) => {
                return (
                    <div className={'row items-center space-x-5'}>
                        <img src={record?.product_images[0]?.image_url} className={'w-14'}/>
                        <p className={'capitalize text-dark-blue-grey font-Poppins-Bold'}>{name}</p>
                    </div>
                )
            }
        }, {
            title: 'Kategori',
            dataIndex: 'categories',
            className: 'text-sm text-dark-grey-blue capitalize',
            key: 'categories',
            render: (categories) => <p>{categories[0].name}</p>
        }, {
            title: 'Brand',
            dataIndex: 'brand',
            className: 'text-sm text-dark-grey-blue capitalize',
            key: 'brand',
            render: (brand) => <p>{brand?.name}</p>
        }, {
            title: 'Sku',
            dataIndex: 'sku',
            className: 'text-sm text-dark-grey-blue uppercase',
            key: 'sku',
        }, {
            title: 'Action',
            className: 'text-sm',
            key: 'action',
            render: (record) => <Link to={`/products/product-list/product-detail/${record?.id}`}>
                <img src={EditSvg} className={"w-9"}/>
            </Link>
        }
    ]

    const expandColumns = [
        {
            title: 'Nama Variant',
            dataIndex: 'name',
            className: 'text-sm text-dark-grey-blue',
        }, {
            title: 'Harga',
            dataIndex: 'price',
            className: 'text-sm text-dark-grey-blue',
        },
    ]

    const expandVariant = (variants) => {
        return (
            <div>
                <Table
                    rowKey={'id'}
                    dataSource={variants}
                    columns={expandColumns}
                    pagination={false}
                />
            </div>
        )
    }

    const onChangePagination = (current) => {
        dispatch(setToolsPage(current))
    }

    return (
        <div>
            <TotalDataComponent slug={'produk'}/>
            <Table
                rowKey={'id'}
                dataSource={productList}
                columns={columns}
                pagination={{
                    pageSize: limit,
                    current: page,
                    total: totalData,
                    onChange: (current) => onChangePagination(current)
                }}
                expandable={{
                    expandedRowRender: record => expandVariant(record?.variants),
                }}
            />
        </div>
    )
}

export default ProductListComponent
