import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        userBankList: [],
        reload: false
    },
    reducers: {
        setUserBankList: (state, action) => {
            state.userBankList = action.payload
        },
        setUserReload: (state, action) => {
            state.reload = action.payload
        },
    },
})

export const { setUserBankList, setUserReload } = userSlice.actions
const userReducer = userSlice.reducer

export default userReducer
