import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getOrderListApi} from "../../api/order.api";
import OrderTabComponent from "./order-tab.component";
import {setOrderList, setOrderLoading} from "../../reducers/order.reducer";
import {setToolsPage, setToolsTotalData} from "../../reducers/tools.reducer";
import OrderFilterComponent from "./order-filter.component";
import ProcessingTabComponent from "./processing-tab.component";
import OrderListComponent from "./order-list.component";
import {APPROVED, PACKING, PROCESSING} from "../../models/order.model";

export const OrdersComponent = () => {
    const dispatch = useDispatch()
    const {toolsReducer, orderReducer, userAdminReducer} = useSelector(state => state)
    const {limit, page, startDate, endDate, searchKey, isDescending} = toolsReducer
    const {activeTab, sellerSelected, reload, processingTab} = orderReducer
    const {adminDetail} = userAdminReducer

    const generateStatus = () => {
        let status = []
        if (activeTab === PROCESSING) {
            switch (processingTab) {
                case null:
                    status = [PROCESSING, APPROVED, PACKING]
                    break;
                default:
                    status = [processingTab]
            }
        } else {
            status = [activeTab]
        }
        return status
    }

    useEffect(() => {
        return () => {
            dispatch(setToolsPage(1))
        }
    }, [])

    useEffect(() => {

        let params = {
            limit,
            page,
            sort: isDescending ? 'created_at-desc' : 'created_at-asc',
            startDate: startDate,
            endDate: endDate,
            status: !!activeTab ? generateStatus() : activeTab,
            search: searchKey,
            seller_id: adminDetail?.id
        }

        dispatch(setOrderLoading(true))

        getOrderListApi(params)
            .then(result => {
                dispatch(setOrderLoading(false))
                dispatch(setOrderList(result.rows))
                dispatch(setToolsTotalData(result.count))
            })
            .catch(err => {
                dispatch(setOrderLoading(false))
                console.log("err getOrderListApi :", err.response)
            })
    }, [activeTab, searchKey, isDescending, startDate, endDate, limit, sellerSelected, reload, adminDetail, processingTab, page])

    return (
        <div className={"card-container"}>
            <OrderTabComponent/>
            <OrderFilterComponent/>
            {activeTab === PROCESSING && <ProcessingTabComponent/>}
            <OrderListComponent/>
        </div>
    )
}
