import React from "react";
import DefaultImage from "../../images/default-image/Profile-Default.png";
import CurrencySvg from "../../images/raw-assets/Group 2223.svg";
import {useSelector} from "react-redux";

const UserBalanceComponent = () => {
    const {adminDetail} = useSelector(state => state.userAdminReducer)
  return (
      <div className={"row items-center justify-between mr-32"}>
          <div className={"row items-center space-x-10"}>
              <img src={adminDetail?.image ? adminDetail?.image : DefaultImage}
                   className={"w-10 h-10 rounded-full"}/>
              <p className={"text-md capitalize font-Poppins-Bold text-dark-grey-blue"}>{adminDetail?.name}</p>
          </div>
          <div className={"row items-center space-x-5"}>
              <img src={CurrencySvg} className={"w-10"}/>
              <div>
                  <p className={"text-faded-blue text-sm font-Poppins-Regular font-semibold"}>Total Balance</p>
                  <p className={"text-lg text-dark-grey-blue font-Poppins-Bold"}>Rp 48.750.000</p>
              </div>
          </div>
      </div>
  )
}

export default UserBalanceComponent
