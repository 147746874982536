import React from "react";
import SearchComponent from "../../components/search.component";
import SortComponent from "../../components/sort.component";
import {useSelector} from "react-redux";
import SelectComponent from "../../components/select.component";

const FilterComponent = () => {
    const {limit} = useSelector(state => state.toolsReducer)

    return (
        <div className={"row items-center justify-between"}>
            <div className={"row items-center space-x-5"}>
                <SearchComponent placeholder={"cari produk.."}/>
                <SortComponent/>
                <SelectComponent options={[20, 50, 100, 150]} slug={'limit'} title={`Tampilkan ${limit} Item`}/>
            </div>
        </div>
    )
}

export default FilterComponent
