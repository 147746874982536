import axios from "axios";
import {baseUrl, token} from "./api.config";
import qs from 'qs';

const getOrderListApi = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${baseUrl()}/api/v1/seller/order/list`,
            {headers: {'Authorization': `Bearer ${token}`},
                params,
                'paramsSerializer': function(params) {
                    return qs.stringify(params, {arrayFormat: 'repeat'})
                },})
            .then(response => resolve(response.data.data))
            .catch(err => reject(err))
    })
}

const updateOrderStateApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${baseUrl()}/api/v1/seller/order/update/status`,
            requestBody,
            {headers: {'Authorization': `Bearer ${token}`}})
            .then(response => resolve(response.data))
            .catch(err => reject(err.response))
    })
}

const getOrderListExportApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${baseUrl()}/api/v1/seller/order/export`,
            requestBody,
            {headers: {'Authorization': `Bearer ${token}`}})
            .then(response => resolve(response.data.data.url))
            .catch(err => reject(err))
    })
}

const getOrderDetailApi = (id) => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${baseUrl()}/api/v1/seller/order/detail/${id}`,
            {headers: {'Authorization': `Bearer ${token}`}})
            .then(response => resolve(response.data.data))
            .catch(err => reject(err))
    })
}

const getHistoryListOrderApi = (id) => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${baseUrl()}/api/v1/seller/order/logs/${id}`,
            {headers: {'Authorization': `Bearer ${token}`}})
            .then(response => resolve(response.data.data))
            .catch(err => reject(err.response))
    })
}

const rejectOrderApi = (id) => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${baseUrl()}/api/v1/seller/order/reject/${id}`,
            {headers: {'Authorization': `Bearer ${token}`}})
            .then(response => resolve(response.data.data))
            .catch(err => reject(err.response))
    })
}

const getTrackingOrderListApi = (id) => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${baseUrl()}/api/v1/seller/order/track/${id}`,
            {headers: {'Authorization': `Bearer ${token}`}})
            .then(response => resolve(response.data.data))
            .catch(err => reject(err.response))
    })
}

export {
    getOrderListApi,
    updateOrderStateApi,
    getOrderListExportApi,
    getOrderDetailApi,
    getHistoryListOrderApi,
    rejectOrderApi,
    getTrackingOrderListApi
}
