import React from "react";
import {useSelector} from "react-redux";

const SaveBankComponent = () => {
    const {validBankAccount, selectedBank} = useSelector(state => state.bankReducer)
    return (
        <div className={"border border-faded-blue rounded-lg bg-ice-blue w-2/3 mt-10 font-semibold"}>
                <p className={"text-dark-grey-blue text-center py-2 border-b"}>{selectedBank?.name}</p>
            <div className={"p-5 col space-y-3"}>
                <div className={"col space-y-1"}>
                    <p className={"text-faded-blue text-xs"}>No. Rekening</p>
                    <p className={"text-dark-grey-blue"}>{validBankAccount?.account_no}</p>
                </div>
                <div className={"col"}>
                    <p className={"text-faded-blue text-xs"}>Atas Nama</p>
                    <p className={"text-dark-grey-blue"}>{validBankAccount?.account_name}</p>
                </div>
            </div>
        </div>
    )
}

export default SaveBankComponent
