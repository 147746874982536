import React from "react";
import {useSelector} from "react-redux";

const DetailComponent = () => {
    const {productDetail} = useSelector(state => state.productReducer)

    return (
        <div className={"col space-y-10"}>
            <div className={"col space-y-3 pb-5 border-b"}>
                <p className={"text-sm"}>Nama produk</p>
                <p className={"text-2xl capitalize font-Poppins-Bold"}>{productDetail?.name}</p>
            </div>
            <div className={"row space-x-5 font-Poppins-Bold text-xs"}>
                <div className={"col space-y-3"}>
                    <p>Gambar Utama</p>
                    <img src={productDetail?.product_images[0].image_url} className={"w-56"}/>
                </div>
                <div className={"col space-y-3"}>
                    <p>Gambar Lainnya</p>
                    <div className={"grid grid-cols-3 gap-3"}>
                        {
                            productDetail?.product_images.map((item, index) => (
                                <img src={item?.image_url} className={"w-20"} key={index}/>
                            ))
                        }
                    </div>
                </div>
            </div>
            <div className={"text-xs col space-y-8"}>
                {
                    productDetail?.product_details.map((item, index) => {
                        if (item.language === 'id') {
                            return (
                                <div className={"col space-y-8"} key={index}>
                                    <div className={"text-sm capitalize"}>
                                        <p className={"font-Poppins-Bold"}>Description</p>
                                        <p>{item?.description}</p>
                                    </div>
                                    <div className={"text-sm capitalize"}>
                                        <p className={"font-Poppins-Bold"}>How To Use</p>
                                        <p>{item?.how_to_use}</p>
                                    </div>
                                </div>

                            )
                        }
                    })
                }
                <div className={"text-sm capitalize"}>
                    <p className={"font-Poppins-Bold"}>Ingridients</p>
                    <p>{productDetail?.ingredient}</p>
                </div>
            </div>
        </div>
    )
}

export default DetailComponent
