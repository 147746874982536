import { createSlice } from '@reduxjs/toolkit'

export const userAdminSlice = createSlice({
    name: 'admin',
    initialState: {
        adminDetail: {},
    },
    reducers: {
        setUserDetail: (state, action) => {
            state.adminDetail = action.payload
        }
    },
})

export const { setUserDetail } = userAdminSlice.actions
const userAdminReducer = userAdminSlice.reducer

export default userAdminReducer
