import React from "react";
import SortSvg from "../images/raw-assets/Group 1937.svg";
import {useDispatch, useSelector} from "react-redux";
import {setToolsIsDescending} from "../reducers/tools.reducer";

const SortComponent = () => {
    const dispatch = useDispatch()
    const {isDescending} = useSelector(state => state.toolsReducer)

    return (
        <button className={'button-rounded-shadow'}
                onClick={() =>dispatch(setToolsIsDescending(!isDescending))}
        >
            <img src={SortSvg} className={"w-5"}/>
            {isDescending ? 'Terbaru' : 'Terlama'}
        </button>
    )
}

export default SortComponent
