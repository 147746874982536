import React from "react";
import {Table} from "antd";
import {bankLogo, defineTextColor, doConvertNumberToRupiahFormat} from "../../ui-utils/general-variable";

const PayoutListComponent = () => {
    const payoutList = [{
        id: 1012023,
        payout: 5000000,
        bank_code: 'mandiri',
        bank_name: 'Mandiri',
        value: '1234567890',
        name: 'Annisa dwi oktavianita',
        date: '12/10/2022 12:22',
        status: 'approve',
        executeDate: '13/10/2022 12:22',
    }, {
        id: 1012024,
        payout: 6000000,
        bank_code: 'bri',
        bank_name: 'Mandiri',
        value: '1234567890',
        name: 'Annisa dwi oktavianita',
        date: '12/10/2022 12:22',
        status: 'pending',
        executeDate: '13/10/2022 12:22',
    }, {
        id: 1012025,
        payout: 7000000,
        bank_code: 'bca',
        bank_name: 'Mandiri',
        value: '1234567890',
        name: 'Annisa dwi oktavianita',
        date: '12/10/2022 12:22',
        status: 'reject',
        executeDate: '13/10/2022 12:22',
    }]
    const columns = [
        {
            title: 'Request ID',
            key: 'id',
            dataIndex: 'id',
            className: "text-sm text-dark-blue-grey font-bold",
        }, {
            title: 'Withdrawing',
            key: 'payout',
            dataIndex: 'payout',
            className: "text-sm text-dark-grey-blue font-bold",
            render: (payout) => doConvertNumberToRupiahFormat(payout)
        },
        {
            title: 'Bank',
            key: 'bank_code',
            dataIndex: 'bank_code',
            className: "text-sm text-dark-blue-grey items-center col font-bold",
            render: (bank_code, record) =>
                <div className={"col items-center"}>
                    <img src={bankLogo[bank_code]} className={'w-14'}/>
                    <p>{record?.value}</p>
                </div>
        }, {
            title: 'Account',
            key: 'accountName',
            dataIndex: 'name',
            className: "text-sm text-dark-blue-grey",
            render: (name) => <p className={"capitalize"}>{name}</p>
        }, {
            title: 'Date',
            key: 'date',
            dataIndex: 'date',
            className: "text-sm text-dark-blue-grey",
        }, {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            className: "text-sm text-dark-blue-grey",
            render: (status) => <p className={`${defineTextColor(status)} capitalize font-bold`}>{status}</p>
        }, {
            title: 'Execute Date',
            key: 'executeDate',
            dataIndex: 'executeDate',
            className: "text-sm text-dark-blue-grey",
            render: (executeDate, record) => record?.status === 'pending' ? '-' : executeDate
        },
    ]
    return (
        <Table
            rowKey={'id'}
            dataSource={payoutList}
            columns={columns}/>
    )
}

export default PayoutListComponent
