import axios from "axios";
import {baseUrl, token} from "./api.config";

const getStoreListApi = () => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${baseUrl()}/api/v1/seller/store/list`,
            {headers: {'Authorization': `Bearer ${token}`}})
            .then(response => resolve(response.data.data.rows))
            .catch(err => reject(err))
    })
}

const createStoreApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${baseUrl()}/api/v1/seller/store/create`,
            requestBody,
            {headers: {'Authorization': `Bearer ${token}`}})
            .then(response => resolve(response.data.data))
            .catch(err => reject(err.response))
    })
}

const updateStoreApi = (id, requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${baseUrl()}/api/v1/seller/store/update/${id}`,
            requestBody,
            {headers: {'Authorization': `Bearer ${token}`}})
            .then(response => resolve(response.data.data))
            .catch(err => reject(err.response))
    })
}

export {
    getStoreListApi,
    createStoreApi,
    updateStoreApi
}
