import React, {useEffect, useState} from "react";
import {CloseOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import {setCreatePayoutModalVisibility, setToolsActiveStep} from "../../reducers/tools.reducer";
import StepComponent from "../../components/step.component";
import {bankLogo, doConvertNumberToRupiahFormat} from "../../ui-utils/general-variable";
import {getBankListApi} from "../../api/bank.api";
import {setUserBankList} from "../../reducers/user.reducer";

const CreateRequestModal = () => {
    const dispatch = useDispatch()
    const {createPayoutModalVisibility, activeStep} = useSelector(state => state.toolsReducer)
    const {userBankList} = useSelector(state => state.userReducer)
    const [nominal, setNominal] = useState(0)
    const [balance, setBalance] = useState(4000000)
    const [bankSelected, setBankSelected] = useState({})

    useEffect(() => {
        getBankListApi()
            .then(result => {
                dispatch(setUserBankList(result))
            })
            .catch(err => {
                console.log('Error getBankListApi', err)
            })
    }, [])

    const closeModal = () => {
        dispatch(setCreatePayoutModalVisibility('hidden'))
    }

    const onChangeStep = (value) => {
        if(value > 0){
            if (activeStep === 0 && ((nominal === 0) || (nominal > balance))) {
                alert('Tidak sesuai')
            } else if (activeStep === 1 && !bankSelected?.bank_code) {
                alert('Pilih bank')
            }  else if (activeStep === 2) {
                console.log('Proceed payout', nominal, bankSelected)
                closeModal()
            } else {
                dispatch(setToolsActiveStep(activeStep + value))
            }
        } else {
            dispatch(setToolsActiveStep(activeStep + value))
        }

    }

    const selectBankHandler = (item) => {
        setBankSelected(item)
    }

    const withdrawContent = () => {
        return (
            <div className={"col space-y-8"}>
                <div>
                    <p className={"text-faded-blue"}>Total Balance</p>
                    <p className={"font-Poppins-Bold text-base text-dark-grey-blue"}>{doConvertNumberToRupiahFormat(4000000)}</p>
                </div>
                <div className={"w-full h-px bg-cloudy-blue-three opacity-60"}/>
                <div className={"row items-center justify-between"}>
                    <p className={"font-semibold text-xs"}>Nilai Penarikan</p>
                    <input
                        className={"border w-3/5 border-faded-blue bg-ice-blue h-7 rounded-md px-3 outline-none font-semibold"}
                        value={nominal} onChange={(e) => setNominal(e.target.value)}/>
                </div>
                <div className={"w-full row items-center"}>
                    <div className={`h-1 bg-water-blue rounded-l-full w-1/4`}/>
                    <div className={"relative w-4 h-4 bg-water-blue rounded-full cursor-grabbing"}/>
                    <div className={`h-1 bg-pale-grey-two rounded-r-full w-3/4`}/>
                </div>
            </div>
        )
    }

    const bankAccountContent = () => {
        return (
            <div>
                Nomor Rekening Penerima
                <div className={"space-y-3 mt-3"}>
                    {
                        userBankList.map((item, index) => (
                            <div
                                className={`row items-center justify-between border rounded-lg px-2 text-xs ${bankSelected === index && 'border-water-blue bg-ice-blue-two'}`}
                                key={index} onClick={() => selectBankHandler(item)}>
                                <input type={'radio'} checked={bankSelected?.id === item.id} className={"w-1/6"}
                                       onChange={() => console.log('checked')}/>
                                <div className={"w-2/6"}>
                                    <img src={bankLogo[item?.bank_code]} className={"w-16 justify-center"}/>
                                </div>
                                <div className={"col justify-around w-3/6 text-dark-grey-blue"}>
                                    <p className={"text-sm font-bold"}>{item?.value}</p>
                                    <p>{item?.name}</p>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        )
    }

    const checkoutContent = () => {
        return (
            <div className={"col items-center justify-center py-5 space-y-8"}>
                <div className={"col items-center"}>
                    <p className={"text-faded-blue font-semibold"}>Jumlah Penarikan</p>
                    <p className={"font-Poppins-Bold text-lg"}>{doConvertNumberToRupiahFormat(nominal)}</p>
                </div>
                <div className={"space-y-2"}>
                    <p className={"text-faded-blue font-semibold text-center"}>Ke Rekening</p>
                    <div
                        className={"row items-center space-x-5 border w-full px-3 bg-ice-blue-two border-water-blue rounded-lg"}>
                        <img src={bankLogo[bankSelected?.bank_code]} className={"w-16"}/>
                        <div>
                            <p className={"font-bold text-dark-grey-blue"}>{bankSelected?.value}</p>
                            <p className={"text-dark-grey-blue"}>{bankSelected?.name}</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const getContent = [withdrawContent(), bankAccountContent(), checkoutContent()]

    return (
        <div
            className={`${createPayoutModalVisibility} absolute fixed flex left-0 top-0 w-full h-full justify-center items-center bg-gray-400 bg-opacity-30 z-20`}>
            <div
                className="col items-center w-1/4 bg-white max-h-fit p-5 rounded-lg shadow-2xl space-y-5 font-Poppins-Regular text-sm">
                <div className={"row items-center justify-between w-full"}>
                    <div/>
                    <p className={"text-dark-blue-grey font-Poppins-Bold text-tiny"}>Request Withdraw</p>
                    <CloseOutlined onClick={closeModal}/>
                </div>
                <div className={"space-y-14 w-60"}>
                    <StepComponent/>
                    <div className={"w-full"}>
                        {
                            getContent[activeStep]
                        }
                    </div>
                    <div className={"row items-center justify-center space-x-5"}>
                        {
                            activeStep !== 0 &&
                            <div className={"row justify-center"} onClick={() => onChangeStep(-1)}>
                                <button
                                    className={"bg-ice-blue-two border w-32 border-light-grey-blue text-faded-blue rounded-full px-10 font-semibold py-1"}>Prev
                                </button>
                            </div>}
                        <div className={"row justify-center"} onClick={() => onChangeStep(1)}>
                            <button
                                className={"gradient-water-blue w-32 font-semibold py-1"}>{activeStep === 2 ? 'Tarik Dana' : 'Next'}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateRequestModal
